import React, { useState } from "react";
import {
  useApolloClient,
  useMutation,
  useQuery,
  useReactiveVar,
} from "@apollo/client";
import {
  Button,
  Grid,
  InputAdornment,
  Input,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  useTheme,
  TextField,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core";
import {
  Add as AddIcon,
  GetApp,
  Search as SearchIcon,
} from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import {
  GET_CLIENT,
  GET_CLIENTS,
  GET_PRODUCTS,
  GET_THERAPIES,
  GET_USERS,
} from "query";
import { UPDATE_CLIENT } from "mutation";
import {
  GetClient,
  GetClientVariables,
  GetClientsVariables,
  GetClients,
  ClientOrderInput,
  ClientWhereInput,
  GetUsers,
  GetUsersVariables,
  ClientFields,
  UserOrderInput,
  UpdateClient,
  UpdateClientVariables,
  GetProducts,
  GetProductsVariables,
  GetTherapies,
  GetTherapiesVariables,
  Profession,
  Specialization,
} from "types/generated/schemaTypes";
import {
  ClientForm,
  FiltersWrapper,
  ButtonChipUpdate,
  ButtonChipDelete,
  DeleteClientForm,
  ButtonChipOrder,
  OrderForm,
} from "components";
import {
  AssignmentLate as NotVerifiedIcon,
  CheckCircle as HasAgentIcon,
  ReportProblem as HasNoAgentIcon,
  HowToReg as ActiveIcon,
} from "@material-ui/icons";
import { userRoleVar, userIdVar } from "localState";
import { UserRole } from "types/generated/schemaTypes";
import { professionsMap } from "utils";

const useStyles = makeStyles((theme) => ({
  noBorderTextField: {
    // backgroundColor: "yellow",
    "& .MuiInput-underline:before": {
      display: "none",
    },
  },
}));

type Status = "ALL" | "VERIFIED" | "NOT_VERIFIED";

export const ActiveClients: React.FC = () => {
  const client = useApolloClient();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const role = useReactiveVar(userRoleVar);
  const userId = useReactiveVar(userIdVar);
  const isAgent = role === UserRole.AGENT;
  const [showDeleteForm, setShowDeleteForm] = useState(false);
  const [currentClient, setCurrentClient] = useState<null | ClientFields>(null);
  const [searchString, setSearchString] = useState("");
  const [offset, setOffset] = useState(0);
  const [status, setStatus] = useState<Status>("ALL");
  const [profession, setProfession] = useState<Profession | "ALL">("ALL");
  const [specialization, setSpecialization] = useState<Specialization | "ALL">(
    "ALL"
  );
  const [filterAgent, setFilterAgent] = useState<string>("ALL");
  const [limit, setLimit] = useState(10);
  const [order, setOrder] = useState<ClientOrderInput>(
    ClientOrderInput.LASTNAME_ASC
  );
  const [showClientForm, setShowClientForm] = useState(false);
  const [showOrderForm, setShowOrderForm] = useState(false);
  const classes = useStyles();
  const {
    data: agentsData,
    loading: agentsLoading,
    error: agentsError,
  } = useQuery<GetUsers, GetUsersVariables>(GET_USERS, {
    fetchPolicy: "network-only",
    variables: {
      where: { active: true, role: UserRole.AGENT },
      order: UserOrderInput.LASTNAME_ASC,
      offset: 0,
      limit: 100,
    },
  });

  const [updateClientMutation] = useMutation<
    UpdateClient,
    UpdateClientVariables
  >(UPDATE_CLIENT);

  const agentsFilterOptions = [
    { label: "Tutti gli agenti", id: "ALL" },
    { label: "Con agente", id: "ASSIGNED" },
    { label: "Senza agente", id: "NOT_ASSIGNED" },
  ];

  const agentsAssignmentOptions = [{ label: "-", id: "" }];

  if (agentsData?.getUsers.users)
    agentsData?.getUsers.users.forEach((agent) => {
      agentsFilterOptions.push({
        label: `${agent.name} ${agent.lastname}`,
        id: agent.id,
      });

      agentsAssignmentOptions.push({
        label: `${agent.name} ${agent.lastname}`,
        id: agent.id,
      });
    });

  const where: ClientWhereInput = {
    active: true,
  };

  if (status !== "ALL") where.verified = status === "VERIFIED";

  if (role === UserRole.AGENT) {
    where.agentId = userId;
  } else {
    if (filterAgent !== "ALL") {
      if (filterAgent === "NOT_ASSIGNED") {
        where.notAssigned = true;
      } else if (filterAgent === "ASSIGNED") {
        where.notAssigned = false;
      } else {
        where.agentId = filterAgent;
      }
    }
  }

  if (searchString.length > 2) where.searchPattern = searchString;

  if (profession !== "ALL") {
    where.profession = profession;
  }
  if (specialization !== "ALL") {
    where.specialization = specialization;
  }

  const {
    data: clientsData,
    loading: clientsLoading,
    error: clientsError,
    refetch: refetchClients,
  } = useQuery<GetClients, GetClientsVariables>(GET_CLIENTS, {
    fetchPolicy: "network-only",
    variables: {
      where,
      order,
      offset,
      limit,
    },
  });

  const handleChangePage = (event: unknown, newPage: number) => {
    setOffset(newPage * limit);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLimit(parseInt(event.target.value, 10));
    setOffset(0);
  };

  const loadClient = async (clientId: string) => {
    const data = await client.query<GetClient, GetClientVariables>({
      query: GET_CLIENT,
      variables: { clientId },
      fetchPolicy: "cache-first",
    });
    setCurrentClient(data.data.getClient);
  };

  const { data: productsData } = useQuery<GetProducts, GetProductsVariables>(
    GET_PRODUCTS,
    {
      fetchPolicy: "cache-first",
      variables: {
        where: { archived: false, forSale: false },
        offset: 0,
        limit: 100,
      },
    }
  );

  const { data: therapiesData } = useQuery<GetTherapies, GetTherapiesVariables>(
    GET_THERAPIES,
    {
      fetchPolicy: "cache-first",
      variables: {
        offset: 0,
        limit: 100,
      },
    }
  );

  return (
    <>
      <FiltersWrapper>
        <Grid container spacing={1} alignItems="flex-end">
          <Grid item xs={6} md={isAgent ? 3 : 2}>
            <FormControl fullWidth={true} variant="standard" size="medium">
              <InputLabel id="order-label">Ordina per</InputLabel>
              <Select
                name="order"
                value={order}
                labelId="order-label"
                id="order"
                label="Ordina per"
                onChange={(e) => {
                  setOffset(0);
                  setOrder(e.target.value as ClientOrderInput);
                }}
              >
                <MenuItem value={ClientOrderInput.CREATION_DESC}>
                  Data creazione ↓
                </MenuItem>
                <MenuItem value={ClientOrderInput.CREATION_ASC}>
                  Data creazione ↑
                </MenuItem>
                <MenuItem value={ClientOrderInput.LASTNAME_DESC}>
                  Cognome (Z-A)
                </MenuItem>
                <MenuItem value={ClientOrderInput.LASTNAME_ASC}>
                  Cognome (A-Z)
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {!isAgent && (
            <>
              <Grid item xs={6} md={2}>
                <FormControl fullWidth={true} variant="standard" size="medium">
                  <InputLabel id="order-label">Visualizza</InputLabel>
                  <Select
                    name="status"
                    value={status}
                    labelId="status-label"
                    id="status"
                    label="Visualizza"
                    onChange={(e) => {
                      setOffset(0);
                      setStatus(e.target.value as Status);
                    }}
                  >
                    <MenuItem value="ALL">Tutti i clienti</MenuItem>
                    <MenuItem value="VERIFIED">
                      Solo clienti verificati
                    </MenuItem>
                    <MenuItem value="NOT_VERIFIED">
                      Solo clienti non verificati
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={6} md={2}>
                <FormControl fullWidth={true}>
                  <Autocomplete
                    options={agentsFilterOptions}
                    value={
                      agentsFilterOptions.find(
                        (agent) => agent.id === filterAgent
                      ) || null
                    }
                    getOptionSelected={(option, test) => {
                      return test.id === option.id;
                    }}
                    onChange={(event, newValue) => {
                      setFilterAgent(newValue?.id || "ALL");
                    }}
                    getOptionLabel={(option) => option.label}
                    // style={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Filtra per agente"
                        variant="standard"
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </>
          )}
          <Grid item xs={6} md={3}>
            <FormControl fullWidth={true} variant="standard" size="medium">
              <InputLabel htmlFor="search">Cerca</InputLabel>
              <Input
                name="searchString"
                id="searchString"
                autoFocus={searchString.length > 2}
                value={searchString}
                onChange={(e) => setSearchString(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>

          <Grid
            item
            xs={12}
            md={isAgent ? 6 : 3}
            style={{ textAlign: "right" }}
          >
            <Button
              color="primary"
              variant="contained"
              size="small"
              onClick={() => {
                setCurrentClient(null);
                setShowClientForm(true);
              }}
              startIcon={<AddIcon />}
            >
              Aggiungi
            </Button>
          </Grid>
        </Grid>

        <Grid container spacing={1} alignItems="flex-end">
          <Grid item xs={12} md={4}>
            {/* PROFESSIONE */}
            <FormControl fullWidth={true} size="medium">
              <InputLabel id="profession-label">Professione</InputLabel>
              <Select
                name="profession"
                value={profession}
                labelId="profession-label"
                id="profession"
                label="Professione"
                onChange={(e) => {
                  setOffset(0);
                  setProfession(e.target.value as Profession | "ALL");
                  setSpecialization("ALL");
                }}
              >
                <MenuItem value="ALL">Tutti le professioni</MenuItem>
                {[
                  Profession.BIOLOGO,
                  // Profession.DIETISTA,
                  Profession.DISCIPLINE_OLISTICHE,
                  Profession.FARMACISTA,
                  // Profession.FISIOTERAPISTA,
                  // Profession.IGIENISTA_DENTALE,
                  Profession.MEDICO_CHIRURGO,
                  Profession.ODONTOIATRA,
                  Profession.PROFESSIONI_SANITARIE_DELLA_RIABILITAZIONE,
                  Profession.PROFESSIONI_SANITARIE_INFERMIERISTICHE_OSTETRICHE,
                  Profession.PROFESSIONI_TECNICO_SANITARIE,
                  Profession.PSICOLOGO,
                  Profession.ALTRO,
                ].map((item) => (
                  <MenuItem key={item} value={item}>
                    {professionsMap[item].label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={4}>
            {/* SPECIALIZZAZIONE */}
            <FormControl fullWidth={true} size="medium">
              <InputLabel id="specialization-label">
                Specializzazione
              </InputLabel>
              <Select
                name="specialization"
                value={specialization}
                labelId="specialization-label"
                id="specialization"
                label="Specializzazione"
                onChange={(e) => {
                  setOffset(0);
                  setSpecialization(e.target.value as Specialization | "ALL");
                }}
              >
                <MenuItem value="ALL">Tutti le specializzazioni</MenuItem>

                {profession !== "ALL" &&
                  professionsMap[profession as Profession].specializations.map(
                    (item: {
                      specialization: Specialization;
                      label: string;
                    }) => {
                      return (
                        <MenuItem
                          key={item.specialization}
                          value={item.specialization}
                        >
                          {item.label}
                        </MenuItem>
                      );
                    }
                  )}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </FiltersWrapper>

      <div
        style={{
          // padding: "16px 10px 8px",
          // backgroundColor: "#FAFAFA",
          margin: "0 auto 15px",
          [theme.breakpoints.up("md")]: {
            // padding: "16px 16px 8px",
            margin: "0 auto 20px",
          },
        }}
      >
        {!isAgent && (
          <Button
            color="primary"
            variant="contained"
            size="small"
            href={`${process.env.REACT_APP_ENDPOINT}/client-without-code`}
            endIcon={<GetApp />}
          >
            CSV clienti senza codice
          </Button>
        )}
        <Button
          color="primary"
          variant="contained"
          size="small"
          href={`${process.env.REACT_APP_ENDPOINT}/active-clients?userId=${userId}`}
          endIcon={<GetApp />}
        >
          CSV clienti attivi
        </Button>
      </div>

      {clientsLoading && <div>Caricamento dei clienti in corso</div>}
      {!clientsLoading &&
        !agentsLoading &&
        !agentsError &&
        !clientsError &&
        clientsData &&
        agentsData && (
          <>
            <Paper>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell variant="head" align="left">
                        STATO
                      </TableCell>
                      <TableCell variant="head" align="left">
                        NOME
                      </TableCell>
                      <TableCell variant="head" align="left">
                        COGNOME
                      </TableCell>
                      {!isAgent && (
                        <TableCell
                          variant="head"
                          align="left"
                          style={{ minWidth: 190 }}
                        >
                          AGENTE DI RIFERIMENTO
                        </TableCell>
                      )}
                      {isAgent && (
                        <TableCell variant="head" align="left">
                          CONTATTI
                        </TableCell>
                      )}
                      <TableCell
                        variant="head"
                        align="left"
                        style={{ width: 190 }}
                      >
                        AZIONI
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {clientsData.getClients.clients.map((client) => {
                      return (
                        <TableRow key={client.id}>
                          <TableCell align="left">
                            <Typography
                              component="span"
                              variant="body1"
                              color={client.active ? "textPrimary" : "error"}
                            >
                              {client.verified ? (
                                <ActiveIcon
                                  htmlColor={theme.palette.success.main}
                                />
                              ) : (
                                <NotVerifiedIcon
                                  htmlColor={theme.palette.warning.main}
                                />
                              )}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography
                              component="span"
                              variant="body1"
                              style={{
                                color: client.verified
                                  ? theme.palette.text.primary
                                  : theme.palette.warning.main,
                              }}
                            >
                              {client.name}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography
                              component="span"
                              variant="body1"
                              style={{
                                color: client.verified
                                  ? theme.palette.text.primary
                                  : theme.palette.warning.main,
                              }}
                            >
                              {client.lastname}
                            </Typography>
                          </TableCell>

                          {isAgent && (
                            <TableCell align="left">
                              <Typography
                                component="span"
                                variant="body1"
                                style={{
                                  color: client.verified
                                    ? theme.palette.text.primary
                                    : theme.palette.warning.main,
                                }}
                              >
                                <a
                                  href={`mailto:${client.email}`}
                                  style={{
                                    color: "inherit",
                                    textDecoration: "none",
                                  }}
                                >
                                  {client.email}
                                </a>
                                {isMobile ? <br /> : " - "}
                                <a
                                  href={`tel:${client.phone}`}
                                  style={{
                                    color: "inherit",
                                    textDecoration: "none",
                                  }}
                                >
                                  {client.phone}
                                </a>
                              </Typography>
                            </TableCell>
                          )}

                          {!isAgent && (
                            <TableCell
                              variant="head"
                              align="left"
                              style={{ width: 250 }}
                            >
                              <div style={{ display: "flex" }}>
                                {client.agent ? (
                                  <HasAgentIcon
                                    style={{ marginRight: 16 }}
                                    htmlColor={theme.palette.success.main}
                                  />
                                ) : (
                                  <HasNoAgentIcon
                                    style={{ marginRight: 16 }}
                                    htmlColor={theme.palette.error.main}
                                  />
                                )}

                                <FormControl fullWidth={true}>
                                  <Autocomplete
                                    options={agentsAssignmentOptions}
                                    value={
                                      agentsAssignmentOptions.find((agent) => {
                                        return agent.id === client.agent?.id;
                                      }) || { label: "-", id: "" }
                                    }
                                    getOptionSelected={(option, test) => {
                                      return test.id === option.id;
                                    }}
                                    onChange={async (_, newValue) => {
                                      await updateClientMutation({
                                        variables: {
                                          clientId: client.id,
                                          clientData: {
                                            agentId: newValue?.id || null,
                                          },
                                        },
                                      });
                                    }}
                                    getOptionLabel={(option) => option.label}
                                    renderInput={(params) => (
                                      <TextField
                                        className={classes.noBorderTextField}
                                        {...params}
                                        label=""
                                        variant="standard"
                                      />
                                    )}
                                  />
                                </FormControl>
                              </div>
                            </TableCell>
                          )}
                          <TableCell align="left">
                            <div style={{ display: "flex" }}>
                              <ButtonChipDelete
                                style={{ marginRight: 16 }}
                                onClick={async () => {
                                  await loadClient(client.id);
                                  setShowDeleteForm(true);
                                  await refetchClients({
                                    where,
                                    order,
                                    offset,
                                    limit,
                                  });
                                }}
                                label="Elimina"
                              />
                              <ButtonChipUpdate
                                onClick={async () => {
                                  await loadClient(client.id);
                                  setShowClientForm(true);
                                }}
                                style={{ marginRight: isAgent ? 16 : 0 }}
                                label="Modifica"
                              />
                              {isAgent && (
                                <ButtonChipOrder
                                  onClick={async () => {
                                    await loadClient(client.id);
                                    setShowOrderForm(true);
                                  }}
                                  label="Richiesta"
                                />
                              )}
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {clientsData.getClients.clients.length < 11 && (
                      <>
                        {new Array(10 - clientsData.getClients.clients.length)
                          .fill(10)
                          .map((_, index) => (
                            <TableRow key={index}>
                              <TableCell variant="head" align="left">
                                &nbsp;
                              </TableCell>
                              <TableCell variant="head" align="left">
                                &nbsp;
                              </TableCell>
                              <TableCell variant="head" align="left">
                                &nbsp;
                              </TableCell>

                              <TableCell variant="head" align="left">
                                &nbsp;
                              </TableCell>

                              <TableCell variant="head" align="left">
                                &nbsp;
                              </TableCell>
                            </TableRow>
                          ))}
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                labelRowsPerPage="Totale clienti per pagina"
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to} di ${count}`
                }
                rowsPerPageOptions={[10, 20, 50]}
                component="div"
                count={clientsData ? clientsData.getClients.total : 0}
                rowsPerPage={limit}
                page={Math.floor(offset / limit)}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </>
        )}

      {showClientForm && (
        <ClientForm
          open={showClientForm}
          onClose={() => {
            setShowClientForm(false);
          }}
          onCreate={async () => {
            setShowClientForm(false);
            await refetchClients({
              where,
              order,
              offset,
              limit,
            });
          }}
          onUpdate={async () => {
            setShowClientForm(false);
          }}
          client={currentClient}
          agentId={role === UserRole.AGENT && userId ? userId : undefined}
        />
      )}

      {currentClient?.id && showDeleteForm && (
        <DeleteClientForm
          clientId={currentClient.id}
          onClose={() => {
            setShowDeleteForm(false);
          }}
          onDelete={() => {
            setShowDeleteForm(false);
          }}
          open={showDeleteForm}
        />
      )}

      {showOrderForm &&
        therapiesData &&
        productsData &&
        currentClient &&
        userId && (
          <OrderForm
            agentId={userId}
            products={productsData.getProducts.products}
            therapies={therapiesData.getTherapies.therapies}
            open={showOrderForm}
            onClose={() => {
              setShowOrderForm(false);
            }}
            clients={[currentClient]}
            onConfirm={async () => {
              setShowOrderForm(false);
            }}
            order={null}
            forceSelection={true}
          />
        )}
    </>
  );
};
