/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: Login
// ====================================================

export interface Login_login {
  __typename: "AuthPayload";
  token: string;
}

export interface Login {
  login: Login_login;
}

export interface LoginVariables {
  email: string;
  password: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateProduct
// ====================================================

export interface CreateProduct_createProduct {
  __typename: "Product";
  id: string;
  name: string;
  description: string | null;
  sku: string;
  unity: number;
  ean: string | null;
  forSale: boolean;
  price: number;
  available: boolean;
}

export interface CreateProduct {
  createProduct: CreateProduct_createProduct;
}

export interface CreateProductVariables {
  productData: CreateProductInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateProduct
// ====================================================

export interface UpdateProduct_updateProduct {
  __typename: "Product";
  id: string;
  name: string;
  description: string | null;
  sku: string;
  unity: number;
  ean: string | null;
  forSale: boolean;
  price: number;
  available: boolean;
}

export interface UpdateProduct {
  updateProduct: UpdateProduct_updateProduct;
}

export interface UpdateProductVariables {
  productId: string;
  productData: UpdateProductInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateTherapy
// ====================================================

export interface CreateTherapy_createTherapy_therapyEntries_products {
  __typename: "Product";
  id: string;
  name: string;
  description: string | null;
  sku: string;
  unity: number;
  ean: string | null;
  forSale: boolean;
  price: number;
  available: boolean;
}

export interface CreateTherapy_createTherapy_therapyEntries {
  __typename: "TherapyEntry";
  quantity: number;
  products: CreateTherapy_createTherapy_therapyEntries_products[];
}

export interface CreateTherapy_createTherapy {
  __typename: "Therapy";
  id: string;
  name: string;
  therapyEntries: CreateTherapy_createTherapy_therapyEntries[];
}

export interface CreateTherapy {
  createTherapy: CreateTherapy_createTherapy;
}

export interface CreateTherapyVariables {
  therapyData: CreateTherapyInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateTherapy
// ====================================================

export interface UpdateTherapy_updateTherapy_therapyEntries_products {
  __typename: "Product";
  id: string;
  name: string;
  description: string | null;
  sku: string;
  unity: number;
  ean: string | null;
  forSale: boolean;
  price: number;
  available: boolean;
}

export interface UpdateTherapy_updateTherapy_therapyEntries {
  __typename: "TherapyEntry";
  quantity: number;
  products: UpdateTherapy_updateTherapy_therapyEntries_products[];
}

export interface UpdateTherapy_updateTherapy {
  __typename: "Therapy";
  id: string;
  name: string;
  therapyEntries: UpdateTherapy_updateTherapy_therapyEntries[];
}

export interface UpdateTherapy {
  updateTherapy: UpdateTherapy_updateTherapy;
}

export interface UpdateTherapyVariables {
  therapyId: string;
  therapyData: UpdateTherapyInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteTherapy
// ====================================================

export interface DeleteTherapy_deleteTherapy {
  __typename: "SuccessMessage";
  message: string;
}

export interface DeleteTherapy {
  deleteTherapy: DeleteTherapy_deleteTherapy;
}

export interface DeleteTherapyVariables {
  therapyId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateUser
// ====================================================

export interface CreateUser_createUser {
  __typename: "User";
  id: string;
  name: string;
  lastname: string;
  active: boolean;
  email: string;
  role: UserRole;
  agentCrm: string | null;
}

export interface CreateUser {
  createUser: CreateUser_createUser;
}

export interface CreateUserVariables {
  userData: CreateUserInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateUser
// ====================================================

export interface UpdateUser_updateUser {
  __typename: "User";
  id: string;
  name: string;
  lastname: string;
  active: boolean;
  email: string;
  role: UserRole;
  agentCrm: string | null;
}

export interface UpdateUser {
  updateUser: UpdateUser_updateUser;
}

export interface UpdateUserVariables {
  userId: string;
  userData: UpdateUserInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetUserStatus
// ====================================================

export interface SetUserStatus_setUserStatus {
  __typename: "User";
  id: string;
  name: string;
  lastname: string;
  active: boolean;
  email: string;
  role: UserRole;
  agentCrm: string | null;
}

export interface SetUserStatus {
  setUserStatus: SetUserStatus_setUserStatus;
}

export interface SetUserStatusVariables {
  userId: string;
  active: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateClient
// ====================================================

export interface CreateClient_createClient_addresses {
  __typename: "Address";
  id: string;
  destinationCode: string | null;
  label: string | null;
  addressLine: string;
  zip: string;
  city: string;
  hamlet: string | null;
  province: string;
  region: string;
  careOf: string | null;
  notes: string | null;
}

export interface CreateClient_createClient_agent {
  __typename: "User";
  id: string;
  name: string;
  lastname: string;
}

export interface CreateClient_createClient {
  __typename: "Client";
  id: string;
  name: string;
  lastname: string;
  active: boolean;
  email: string;
  verified: boolean;
  addresses: CreateClient_createClient_addresses[];
  profession: Profession;
  specialization: Specialization;
  customProfession: string | null;
  customSpecialization: string | null;
  notes: string | null;
  phone: string;
  fiscalCode: string | null;
  agent: CreateClient_createClient_agent | null;
  lastOrder: any | null;
}

export interface CreateClient {
  createClient: CreateClient_createClient;
}

export interface CreateClientVariables {
  clientData: CreateClientInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateClient
// ====================================================

export interface UpdateClient_updateClient_addresses {
  __typename: "Address";
  id: string;
  destinationCode: string | null;
  label: string | null;
  addressLine: string;
  zip: string;
  city: string;
  hamlet: string | null;
  province: string;
  region: string;
  careOf: string | null;
  notes: string | null;
}

export interface UpdateClient_updateClient_agent {
  __typename: "User";
  id: string;
  name: string;
  lastname: string;
}

export interface UpdateClient_updateClient {
  __typename: "Client";
  id: string;
  name: string;
  lastname: string;
  active: boolean;
  email: string;
  verified: boolean;
  addresses: UpdateClient_updateClient_addresses[];
  profession: Profession;
  specialization: Specialization;
  customProfession: string | null;
  customSpecialization: string | null;
  notes: string | null;
  phone: string;
  fiscalCode: string | null;
  agent: UpdateClient_updateClient_agent | null;
  lastOrder: any | null;
}

export interface UpdateClient {
  updateClient: UpdateClient_updateClient;
}

export interface UpdateClientVariables {
  clientId: string;
  clientData: UpdateClientInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ActivateClient
// ====================================================

export interface ActivateClient_activateClient_addresses {
  __typename: "Address";
  id: string;
  destinationCode: string | null;
  label: string | null;
  addressLine: string;
  zip: string;
  city: string;
  hamlet: string | null;
  province: string;
  region: string;
  careOf: string | null;
  notes: string | null;
}

export interface ActivateClient_activateClient_agent {
  __typename: "User";
  id: string;
  name: string;
  lastname: string;
}

export interface ActivateClient_activateClient {
  __typename: "Client";
  id: string;
  name: string;
  lastname: string;
  active: boolean;
  email: string;
  verified: boolean;
  addresses: ActivateClient_activateClient_addresses[];
  profession: Profession;
  specialization: Specialization;
  customProfession: string | null;
  customSpecialization: string | null;
  notes: string | null;
  phone: string;
  fiscalCode: string | null;
  agent: ActivateClient_activateClient_agent | null;
  lastOrder: any | null;
}

export interface ActivateClient {
  activateClient: ActivateClient_activateClient;
}

export interface ActivateClientVariables {
  clientId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeactivateClient
// ====================================================

export interface DeactivateClient_deactivateClient_addresses {
  __typename: "Address";
  id: string;
  destinationCode: string | null;
  label: string | null;
  addressLine: string;
  zip: string;
  city: string;
  hamlet: string | null;
  province: string;
  region: string;
  careOf: string | null;
  notes: string | null;
}

export interface DeactivateClient_deactivateClient_agent {
  __typename: "User";
  id: string;
  name: string;
  lastname: string;
}

export interface DeactivateClient_deactivateClient {
  __typename: "Client";
  id: string;
  name: string;
  lastname: string;
  active: boolean;
  email: string;
  verified: boolean;
  addresses: DeactivateClient_deactivateClient_addresses[];
  profession: Profession;
  specialization: Specialization;
  customProfession: string | null;
  customSpecialization: string | null;
  notes: string | null;
  phone: string;
  fiscalCode: string | null;
  agent: DeactivateClient_deactivateClient_agent | null;
  lastOrder: any | null;
}

export interface DeactivateClient {
  deactivateClient: DeactivateClient_deactivateClient;
}

export interface DeactivateClientVariables {
  clientId: string;
  notes?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateOrder
// ====================================================

export interface CreateOrder_createOrder_client {
  __typename: "Client";
  id: string;
  name: string;
  lastname: string;
  active: boolean;
  email: string;
  verified: boolean;
  profession: Profession;
  specialization: Specialization;
  customProfession: string | null;
  customSpecialization: string | null;
  phone: string;
  fiscalCode: string | null;
  lastOrder: any | null;
}

export interface CreateOrder_createOrder_agent {
  __typename: "User";
  id: string;
  name: string;
  lastname: string;
  email: string;
  agentCrm: string | null;
}

export interface CreateOrder_createOrder_operator {
  __typename: "User";
  id: string;
  name: string;
  lastname: string;
  email: string;
}

export interface CreateOrder_createOrder_address {
  __typename: "Address";
  id: string;
  destinationCode: string | null;
  label: string | null;
  addressLine: string;
  zip: string;
  city: string;
  hamlet: string | null;
  province: string;
  region: string;
  careOf: string | null;
  notes: string | null;
}

export interface CreateOrder_createOrder_selectedTherapies {
  __typename: "SelectedTherapy";
  therapyId: string;
  productIds: string[];
}

export interface CreateOrder_createOrder_selectedProducts {
  __typename: "SelectedProduct";
  productId: string;
  quantity: number;
}

export interface CreateOrder_createOrder {
  __typename: "Order";
  id: string;
  status: OrderStatus;
  date: any;
  client: CreateOrder_createOrder_client;
  deliveryNotes: string | null;
  agent: CreateOrder_createOrder_agent;
  operator: CreateOrder_createOrder_operator | null;
  address: CreateOrder_createOrder_address;
  selectedTherapies: CreateOrder_createOrder_selectedTherapies[];
  selectedProducts: CreateOrder_createOrder_selectedProducts[];
}

export interface CreateOrder {
  createOrder: CreateOrder_createOrder;
}

export interface CreateOrderVariables {
  orderData: CreateOrderInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateOrder
// ====================================================

export interface UpdateOrder_updateOrder_client {
  __typename: "Client";
  id: string;
  name: string;
  lastname: string;
  active: boolean;
  email: string;
  verified: boolean;
  profession: Profession;
  specialization: Specialization;
  customProfession: string | null;
  customSpecialization: string | null;
  phone: string;
  fiscalCode: string | null;
  lastOrder: any | null;
}

export interface UpdateOrder_updateOrder_agent {
  __typename: "User";
  id: string;
  name: string;
  lastname: string;
  email: string;
  agentCrm: string | null;
}

export interface UpdateOrder_updateOrder_operator {
  __typename: "User";
  id: string;
  name: string;
  lastname: string;
  email: string;
}

export interface UpdateOrder_updateOrder_address {
  __typename: "Address";
  id: string;
  destinationCode: string | null;
  label: string | null;
  addressLine: string;
  zip: string;
  city: string;
  hamlet: string | null;
  province: string;
  region: string;
  careOf: string | null;
  notes: string | null;
}

export interface UpdateOrder_updateOrder_selectedTherapies {
  __typename: "SelectedTherapy";
  therapyId: string;
  productIds: string[];
}

export interface UpdateOrder_updateOrder_selectedProducts {
  __typename: "SelectedProduct";
  productId: string;
  quantity: number;
}

export interface UpdateOrder_updateOrder {
  __typename: "Order";
  id: string;
  status: OrderStatus;
  date: any;
  client: UpdateOrder_updateOrder_client;
  deliveryNotes: string | null;
  agent: UpdateOrder_updateOrder_agent;
  operator: UpdateOrder_updateOrder_operator | null;
  address: UpdateOrder_updateOrder_address;
  selectedTherapies: UpdateOrder_updateOrder_selectedTherapies[];
  selectedProducts: UpdateOrder_updateOrder_selectedProducts[];
}

export interface UpdateOrder {
  updateOrder: UpdateOrder_updateOrder;
}

export interface UpdateOrderVariables {
  orderId: string;
  orderData: UpdateOrderInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CloseOrder
// ====================================================

export interface CloseOrder_closeOrder_client {
  __typename: "OrderClosedClient";
  id: string;
  name: string;
  lastname: string;
  email: string;
  phone: string;
  fiscalCode: string | null;
  profession: string;
  specialization: string;
  addressLine: string;
  zip: string;
  city: string;
  hamlet: string | null;
  province: string;
  region: string;
  careOf: string | null;
  destinationCode: string;
}

export interface CloseOrder_closeOrder_agent {
  __typename: "OrderClosedAgent";
  id: string;
  crmCode: string;
  name: string;
  lastname: string;
  email: string;
}

export interface CloseOrder_closeOrder_productUnities {
  __typename: "OrderClosedProductUnities";
  id: string;
  name: string;
  boxUnities: number;
  totalUnities: number;
  sku: string;
}

export interface CloseOrder_closeOrder_selectedProducts {
  __typename: "OrderClosedProducts";
  id: string;
  quantity: number;
  name: string;
  sku: string;
}

export interface CloseOrder_closeOrder_selectedTherapies {
  __typename: "OrderClosedTherapies";
  id: string;
  name: string;
  quantity: number;
}

export interface CloseOrder_closeOrder {
  __typename: "OrderClosed";
  id: string;
  finalStatus: OrderFinalStatus;
  orderDate: any;
  client: CloseOrder_closeOrder_client;
  agent: CloseOrder_closeOrder_agent;
  orderNotes: string | null;
  deliveryNotes: string | null;
  productUnities: (CloseOrder_closeOrder_productUnities | null)[];
  selectedProducts: (CloseOrder_closeOrder_selectedProducts | null)[];
  selectedTherapies: (CloseOrder_closeOrder_selectedTherapies | null)[];
}

export interface CloseOrder {
  closeOrder: CloseOrder_closeOrder;
}

export interface CloseOrderVariables {
  orderId: string;
  finalStatus: OrderFinalStatus;
  notes?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteOrder
// ====================================================

export interface DeleteOrder_deleteOrder {
  __typename: "SuccessMessage";
  message: string;
}

export interface DeleteOrder {
  deleteOrder: DeleteOrder_deleteOrder;
}

export interface DeleteOrderVariables {
  orderId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateDocument
// ====================================================

export interface CreateDocument_createMedicalDocument {
  __typename: "MedicalDocument";
  id: string;
  name: string;
  unity: number;
  crmCode: string;
  cover: string | null;
}

export interface CreateDocument {
  createMedicalDocument: CreateDocument_createMedicalDocument;
}

export interface CreateDocumentVariables {
  medicalDocumentData: CreateMedicalDocumentInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateDocument
// ====================================================

export interface UpdateDocument_updateMedicalDocument {
  __typename: "MedicalDocument";
  id: string;
  name: string;
  unity: number;
  crmCode: string;
  cover: string | null;
  available: boolean;
}

export interface UpdateDocument {
  updateMedicalDocument: UpdateDocument_updateMedicalDocument;
}

export interface UpdateDocumentVariables {
  medicalDocumentId: string;
  medicalDocumentData: UpdateMedicalDocumentInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteDocument
// ====================================================

export interface DeleteDocument_deleteMedicalDocument {
  __typename: "SuccessMessage";
  message: string;
}

export interface DeleteDocument {
  deleteMedicalDocument: DeleteDocument_deleteMedicalDocument;
}

export interface DeleteDocumentVariables {
  medicalDocumentId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteFile
// ====================================================

export interface deleteFile_deleteFile {
  __typename: "SuccessMessage";
  message: string;
}

export interface deleteFile {
  deleteFile: deleteFile_deleteFile;
}

export interface deleteFileVariables {
  url: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RequestDocumentsSamples
// ====================================================

export interface RequestDocumentsSamples_requestDocumentsSamples {
  __typename: "SuccessMessage";
  message: string;
}

export interface RequestDocumentsSamples {
  requestDocumentsSamples: RequestDocumentsSamples_requestDocumentsSamples;
}

export interface RequestDocumentsSamplesVariables {
  documentsSamples: RequestDocumentsSamplesInput[];
  notes?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RequestProductsSamples
// ====================================================

export interface RequestProductsSamples_requestProductsSamples {
  __typename: "SuccessMessage";
  message: string;
}

export interface RequestProductsSamples {
  requestProductsSamples: RequestProductsSamples_requestProductsSamples;
}

export interface RequestProductsSamplesVariables {
  productsSamples: RequestProductsSamplesInput[];
  notes?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ResetPassword
// ====================================================

export interface ResetPassword_resetPassword {
  __typename: "SuccessMessage";
  message: string;
}

export interface ResetPassword {
  resetPassword: ResetPassword_resetPassword;
}

export interface ResetPasswordVariables {
  resetToken: string;
  password: string;
  confirmPassword: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RequestResetPassword
// ====================================================

export interface RequestResetPassword_requestResetPassword {
  __typename: "SuccessMessage";
  message: string;
}

export interface RequestResetPassword {
  requestResetPassword: RequestResetPassword_requestResetPassword;
}

export interface RequestResetPasswordVariables {
  email: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdatePassword
// ====================================================

export interface UpdatePassword_updatePassword {
  __typename: "SuccessMessage";
  message: string;
}

export interface UpdatePassword {
  updatePassword: UpdatePassword_updatePassword;
}

export interface UpdatePasswordVariables {
  userId: string;
  password: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetMaintenanceMode
// ====================================================

export interface SetMaintenanceMode {
  setMaintenanceMode: boolean;
}

export interface SetMaintenanceModeVariables {
  active: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetSettings
// ====================================================

export interface SetSettings_setSettings {
  __typename: "Settings";
  id: string;
  value: string;
}

export interface SetSettings {
  setSettings: SetSettings_setSettings | null;
}

export interface SetSettingsVariables {
  id: string;
  value: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateSalesClient
// ====================================================

export interface CreateSalesClient_createSalesClient_salesAddresses {
  __typename: "SalesAddress";
  id: string;
  destinationCode: string | null;
  name: string;
  addressLine: string;
  zip: string;
  city: string;
  hamlet: string | null;
  province: string;
  phone: string | null;
  notes: string | null;
}

export interface CreateSalesClient_createSalesClient_agent {
  __typename: "User";
  id: string;
  name: string;
  lastname: string;
}

export interface CreateSalesClient_createSalesClient {
  __typename: "SalesClient";
  id: string;
  name: string;
  addressLine: string;
  zip: string;
  city: string;
  hamlet: string | null;
  province: string;
  sdi: string | null;
  pec: string | null;
  iban: string | null;
  active: boolean;
  email: string;
  verified: boolean;
  phone: string;
  vat: string;
  notes: string | null;
  salesAddresses: CreateSalesClient_createSalesClient_salesAddresses[];
  agent: CreateSalesClient_createSalesClient_agent | null;
  lastOrder: any | null;
}

export interface CreateSalesClient {
  createSalesClient: CreateSalesClient_createSalesClient;
}

export interface CreateSalesClientVariables {
  salesClientData: CreateSalesClientInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateSalesClient
// ====================================================

export interface UpdateSalesClient_updateSalesClient_salesAddresses {
  __typename: "SalesAddress";
  id: string;
  destinationCode: string | null;
  name: string;
  addressLine: string;
  zip: string;
  city: string;
  hamlet: string | null;
  province: string;
  phone: string | null;
  notes: string | null;
}

export interface UpdateSalesClient_updateSalesClient_agent {
  __typename: "User";
  id: string;
  name: string;
  lastname: string;
}

export interface UpdateSalesClient_updateSalesClient {
  __typename: "SalesClient";
  id: string;
  name: string;
  addressLine: string;
  zip: string;
  city: string;
  hamlet: string | null;
  province: string;
  sdi: string | null;
  pec: string | null;
  iban: string | null;
  active: boolean;
  email: string;
  verified: boolean;
  phone: string;
  vat: string;
  notes: string | null;
  salesAddresses: UpdateSalesClient_updateSalesClient_salesAddresses[];
  agent: UpdateSalesClient_updateSalesClient_agent | null;
  lastOrder: any | null;
}

export interface UpdateSalesClient {
  updateSalesClient: UpdateSalesClient_updateSalesClient;
}

export interface UpdateSalesClientVariables {
  salesClientId: string;
  salesClientData: UpdateSalesClientInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ActivateSalesClient
// ====================================================

export interface ActivateSalesClient_activateSalesClient_salesAddresses {
  __typename: "SalesAddress";
  id: string;
  destinationCode: string | null;
  name: string;
  addressLine: string;
  zip: string;
  city: string;
  hamlet: string | null;
  province: string;
  phone: string | null;
  notes: string | null;
}

export interface ActivateSalesClient_activateSalesClient_agent {
  __typename: "User";
  id: string;
  name: string;
  lastname: string;
}

export interface ActivateSalesClient_activateSalesClient {
  __typename: "SalesClient";
  id: string;
  name: string;
  addressLine: string;
  zip: string;
  city: string;
  hamlet: string | null;
  province: string;
  sdi: string | null;
  pec: string | null;
  iban: string | null;
  active: boolean;
  email: string;
  verified: boolean;
  phone: string;
  vat: string;
  notes: string | null;
  salesAddresses: ActivateSalesClient_activateSalesClient_salesAddresses[];
  agent: ActivateSalesClient_activateSalesClient_agent | null;
  lastOrder: any | null;
}

export interface ActivateSalesClient {
  activateSalesClient: ActivateSalesClient_activateSalesClient;
}

export interface ActivateSalesClientVariables {
  salesClientId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeactivateSalesClient
// ====================================================

export interface DeactivateSalesClient_deactivateSalesClient_salesAddresses {
  __typename: "SalesAddress";
  id: string;
  destinationCode: string | null;
  name: string;
  addressLine: string;
  zip: string;
  city: string;
  hamlet: string | null;
  province: string;
  phone: string | null;
  notes: string | null;
}

export interface DeactivateSalesClient_deactivateSalesClient_agent {
  __typename: "User";
  id: string;
  name: string;
  lastname: string;
}

export interface DeactivateSalesClient_deactivateSalesClient {
  __typename: "SalesClient";
  id: string;
  name: string;
  addressLine: string;
  zip: string;
  city: string;
  hamlet: string | null;
  province: string;
  sdi: string | null;
  pec: string | null;
  iban: string | null;
  active: boolean;
  email: string;
  verified: boolean;
  phone: string;
  vat: string;
  notes: string | null;
  salesAddresses: DeactivateSalesClient_deactivateSalesClient_salesAddresses[];
  agent: DeactivateSalesClient_deactivateSalesClient_agent | null;
  lastOrder: any | null;
}

export interface DeactivateSalesClient {
  deactivateSalesClient: DeactivateSalesClient_deactivateSalesClient;
}

export interface DeactivateSalesClientVariables {
  salesClientId: string;
  notes?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateSalesOrder
// ====================================================

export interface CreateSalesOrder_createSalesOrder_salesClient {
  __typename: "SalesClient";
  id: string;
  name: string;
  addressLine: string;
  zip: string;
  city: string;
  hamlet: string | null;
  province: string;
  notes: string | null;
  iban: string | null;
  pec: string | null;
  sdi: string | null;
  active: boolean;
  email: string;
  verified: boolean;
  phone: string;
  vat: string;
  lastOrder: any | null;
}

export interface CreateSalesOrder_createSalesOrder_agent {
  __typename: "User";
  id: string;
  name: string;
  lastname: string;
  email: string;
  agentCrm: string | null;
}

export interface CreateSalesOrder_createSalesOrder_operator {
  __typename: "User";
  id: string;
  name: string;
  lastname: string;
  email: string;
}

export interface CreateSalesOrder_createSalesOrder_salesAddress {
  __typename: "SalesAddress";
  id: string;
  destinationCode: string | null;
  name: string;
  addressLine: string;
  zip: string;
  city: string;
  hamlet: string | null;
  province: string;
  notes: string | null;
  phone: string | null;
}

export interface CreateSalesOrder_createSalesOrder_selectedSalesProducts {
  __typename: "SelectedSalesProduct";
  productId: string;
  quantitySale: number;
  quantityDiscount: number;
  quantitySample: number;
}

export interface CreateSalesOrder_createSalesOrder {
  __typename: "SalesOrder";
  id: string;
  status: SalesOrderStatus;
  payment: SalesOrderPayment;
  date: any;
  discount: number;
  salesClient: CreateSalesOrder_createSalesOrder_salesClient;
  deliveryNotes: string | null;
  agent: CreateSalesOrder_createSalesOrder_agent;
  operator: CreateSalesOrder_createSalesOrder_operator | null;
  salesAddress: CreateSalesOrder_createSalesOrder_salesAddress;
  selectedSalesProducts: CreateSalesOrder_createSalesOrder_selectedSalesProducts[];
}

export interface CreateSalesOrder {
  createSalesOrder: CreateSalesOrder_createSalesOrder;
}

export interface CreateSalesOrderVariables {
  salesOrderData: CreateSalesOrderInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateSalesOrder
// ====================================================

export interface UpdateSalesOrder_updateSalesOrder_salesClient {
  __typename: "SalesClient";
  id: string;
  name: string;
  addressLine: string;
  zip: string;
  city: string;
  hamlet: string | null;
  province: string;
  notes: string | null;
  iban: string | null;
  pec: string | null;
  sdi: string | null;
  active: boolean;
  email: string;
  verified: boolean;
  phone: string;
  vat: string;
  lastOrder: any | null;
}

export interface UpdateSalesOrder_updateSalesOrder_agent {
  __typename: "User";
  id: string;
  name: string;
  lastname: string;
  email: string;
  agentCrm: string | null;
}

export interface UpdateSalesOrder_updateSalesOrder_operator {
  __typename: "User";
  id: string;
  name: string;
  lastname: string;
  email: string;
}

export interface UpdateSalesOrder_updateSalesOrder_salesAddress {
  __typename: "SalesAddress";
  id: string;
  destinationCode: string | null;
  name: string;
  addressLine: string;
  zip: string;
  city: string;
  hamlet: string | null;
  province: string;
  notes: string | null;
  phone: string | null;
}

export interface UpdateSalesOrder_updateSalesOrder_selectedSalesProducts {
  __typename: "SelectedSalesProduct";
  productId: string;
  quantitySale: number;
  quantityDiscount: number;
  quantitySample: number;
}

export interface UpdateSalesOrder_updateSalesOrder {
  __typename: "SalesOrder";
  id: string;
  status: SalesOrderStatus;
  payment: SalesOrderPayment;
  date: any;
  discount: number;
  salesClient: UpdateSalesOrder_updateSalesOrder_salesClient;
  deliveryNotes: string | null;
  agent: UpdateSalesOrder_updateSalesOrder_agent;
  operator: UpdateSalesOrder_updateSalesOrder_operator | null;
  salesAddress: UpdateSalesOrder_updateSalesOrder_salesAddress;
  selectedSalesProducts: UpdateSalesOrder_updateSalesOrder_selectedSalesProducts[];
}

export interface UpdateSalesOrder {
  updateSalesOrder: UpdateSalesOrder_updateSalesOrder;
}

export interface UpdateSalesOrderVariables {
  salesOrderId: string;
  salesOrderData: UpdateSalesOrderInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CloseSalesOrder
// ====================================================

export interface CloseSalesOrder_closeSalesOrder_salesClient {
  __typename: "SalesOrderClosedClient";
  id: string;
  name: string;
  email: string;
  destinationCode: string;
  phone: string;
  vat: string | null;
  iban: string | null;
  sdi: string | null;
  pec: string | null;
  addressLine: string;
  zip: string;
  city: string;
  hamlet: string | null;
  province: string;
  shippingName: string;
  shippingPhone: string | null;
  shippingAddressLine: string;
  shippingZip: string;
  shippingCity: string;
  shippingHamlet: string | null;
  shippingProvince: string;
}

export interface CloseSalesOrder_closeSalesOrder_agent {
  __typename: "SalesOrderClosedAgent";
  id: string;
  crmCode: string;
  name: string;
  lastname: string;
  email: string;
}

export interface CloseSalesOrder_closeSalesOrder_selectedSalesProducts {
  __typename: "SalesOrderClosedProducts";
  id: string;
  quantitySale: number;
  quantityDiscount: number;
  quantitySample: number;
  name: string;
  sku: string;
  ean: string;
  price: number;
}

export interface CloseSalesOrder_closeSalesOrder {
  __typename: "SalesOrderClosed";
  id: string;
  finalStatus: SalesOrderFinalStatus;
  finalPayment: SalesOrderPayment;
  salesOrderDate: any;
  salesOrderNotes: string | null;
  deliveryNotes: string | null;
  discount: number;
  salesClient: CloseSalesOrder_closeSalesOrder_salesClient;
  agent: CloseSalesOrder_closeSalesOrder_agent;
  selectedSalesProducts: CloseSalesOrder_closeSalesOrder_selectedSalesProducts[];
}

export interface CloseSalesOrder {
  closeSalesOrder: CloseSalesOrder_closeSalesOrder;
}

export interface CloseSalesOrderVariables {
  salesOrderId: string;
  finalStatus: SalesOrderFinalStatus;
  notes?: string | null;
  discount: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteSalesOrder
// ====================================================

export interface DeleteSalesOrder_deleteSalesOrder {
  __typename: "SuccessMessage";
  message: string;
}

export interface DeleteSalesOrder {
  deleteSalesOrder: DeleteSalesOrder_deleteSalesOrder;
}

export interface DeleteSalesOrderVariables {
  salesOrderId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Me
// ====================================================

export interface Me_me {
  __typename: "User";
  id: string;
  email: string;
  role: UserRole;
  name: string;
  lastname: string;
}

export interface Me {
  me: Me_me | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetProducts
// ====================================================

export interface GetProducts_getProducts_products {
  __typename: "Product";
  id: string;
  name: string;
  description: string | null;
  sku: string;
  unity: number;
  ean: string | null;
  forSale: boolean;
  price: number;
  available: boolean;
}

export interface GetProducts_getProducts {
  __typename: "GetProductsResults";
  total: number;
  products: GetProducts_getProducts_products[];
}

export interface GetProducts {
  getProducts: GetProducts_getProducts;
}

export interface GetProductsVariables {
  offset?: number | null;
  limit?: number | null;
  where?: ProductWhereInput | null;
  order?: ProductOrderInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetProduct
// ====================================================

export interface GetProduct_getProduct {
  __typename: "Product";
  id: string;
  name: string;
  description: string | null;
  sku: string;
  unity: number;
  ean: string | null;
  forSale: boolean;
  price: number;
  available: boolean;
}

export interface GetProduct {
  getProduct: GetProduct_getProduct | null;
}

export interface GetProductVariables {
  productId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTherapy
// ====================================================

export interface GetTherapy_getTherapy_therapyEntries_products {
  __typename: "Product";
  id: string;
  name: string;
  description: string | null;
  sku: string;
  unity: number;
  ean: string | null;
  forSale: boolean;
  price: number;
  available: boolean;
}

export interface GetTherapy_getTherapy_therapyEntries {
  __typename: "TherapyEntry";
  quantity: number;
  products: GetTherapy_getTherapy_therapyEntries_products[];
}

export interface GetTherapy_getTherapy {
  __typename: "Therapy";
  id: string;
  name: string;
  therapyEntries: GetTherapy_getTherapy_therapyEntries[];
}

export interface GetTherapy {
  getTherapy: GetTherapy_getTherapy | null;
}

export interface GetTherapyVariables {
  therapyId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTherapies
// ====================================================

export interface GetTherapies_getTherapies_therapies_therapyEntries_products {
  __typename: "Product";
  id: string;
  name: string;
  description: string | null;
  sku: string;
  unity: number;
  ean: string | null;
  forSale: boolean;
  price: number;
  available: boolean;
}

export interface GetTherapies_getTherapies_therapies_therapyEntries {
  __typename: "TherapyEntry";
  quantity: number;
  products: GetTherapies_getTherapies_therapies_therapyEntries_products[];
}

export interface GetTherapies_getTherapies_therapies {
  __typename: "Therapy";
  id: string;
  name: string;
  therapyEntries: GetTherapies_getTherapies_therapies_therapyEntries[];
}

export interface GetTherapies_getTherapies {
  __typename: "GetTherapiesResults";
  total: number;
  therapies: GetTherapies_getTherapies_therapies[];
}

export interface GetTherapies {
  getTherapies: GetTherapies_getTherapies;
}

export interface GetTherapiesVariables {
  offset?: number | null;
  limit?: number | null;
  where?: TherapyWhereInput | null;
  order?: TherapyOrderInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetUsers
// ====================================================

export interface GetUsers_getUsers_users {
  __typename: "User";
  id: string;
  name: string;
  lastname: string;
  active: boolean;
  email: string;
  role: UserRole;
  agentCrm: string | null;
}

export interface GetUsers_getUsers {
  __typename: "GetUsersResults";
  total: number;
  users: GetUsers_getUsers_users[];
}

export interface GetUsers {
  getUsers: GetUsers_getUsers;
}

export interface GetUsersVariables {
  offset?: number | null;
  limit?: number | null;
  where?: UserWhereInput | null;
  order?: UserOrderInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetUser
// ====================================================

export interface GetUser_getUser {
  __typename: "User";
  id: string;
  name: string;
  lastname: string;
  active: boolean;
  email: string;
  role: UserRole;
  agentCrm: string | null;
}

export interface GetUser {
  getUser: GetUser_getUser | null;
}

export interface GetUserVariables {
  userId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetClients
// ====================================================

export interface GetClients_getClients_clients_addresses {
  __typename: "Address";
  id: string;
  destinationCode: string | null;
  label: string | null;
  addressLine: string;
  zip: string;
  city: string;
  hamlet: string | null;
  province: string;
  region: string;
  careOf: string | null;
  notes: string | null;
}

export interface GetClients_getClients_clients_agent {
  __typename: "User";
  id: string;
  name: string;
  lastname: string;
}

export interface GetClients_getClients_clients {
  __typename: "Client";
  id: string;
  name: string;
  lastname: string;
  active: boolean;
  email: string;
  verified: boolean;
  addresses: GetClients_getClients_clients_addresses[];
  profession: Profession;
  specialization: Specialization;
  customProfession: string | null;
  customSpecialization: string | null;
  notes: string | null;
  phone: string;
  fiscalCode: string | null;
  agent: GetClients_getClients_clients_agent | null;
  lastOrder: any | null;
}

export interface GetClients_getClients {
  __typename: "GetClientsResults";
  total: number;
  clients: GetClients_getClients_clients[];
}

export interface GetClients {
  getClients: GetClients_getClients;
}

export interface GetClientsVariables {
  offset?: number | null;
  limit?: number | null;
  where?: ClientWhereInput | null;
  order?: ClientOrderInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetClientsSimple
// ====================================================

export interface GetClientsSimple_getClients_clients {
  __typename: "Client";
  id: string;
  name: string;
  lastname: string;
}

export interface GetClientsSimple_getClients {
  __typename: "GetClientsResults";
  total: number;
  clients: GetClientsSimple_getClients_clients[];
}

export interface GetClientsSimple {
  getClients: GetClientsSimple_getClients;
}

export interface GetClientsSimpleVariables {
  offset?: number | null;
  limit?: number | null;
  where?: ClientWhereInput | null;
  order?: ClientOrderInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetClient
// ====================================================

export interface GetClient_getClient_addresses {
  __typename: "Address";
  id: string;
  destinationCode: string | null;
  label: string | null;
  addressLine: string;
  zip: string;
  city: string;
  hamlet: string | null;
  province: string;
  region: string;
  careOf: string | null;
  notes: string | null;
}

export interface GetClient_getClient_agent {
  __typename: "User";
  id: string;
  name: string;
  lastname: string;
}

export interface GetClient_getClient {
  __typename: "Client";
  id: string;
  name: string;
  lastname: string;
  active: boolean;
  email: string;
  verified: boolean;
  addresses: GetClient_getClient_addresses[];
  profession: Profession;
  specialization: Specialization;
  customProfession: string | null;
  customSpecialization: string | null;
  notes: string | null;
  phone: string;
  fiscalCode: string | null;
  agent: GetClient_getClient_agent | null;
  lastOrder: any | null;
}

export interface GetClient {
  getClient: GetClient_getClient | null;
}

export interface GetClientVariables {
  clientId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetOrders
// ====================================================

export interface GetOrders_getOrders_orders_client {
  __typename: "Client";
  id: string;
  name: string;
  lastname: string;
  active: boolean;
  email: string;
  verified: boolean;
  profession: Profession;
  specialization: Specialization;
  customProfession: string | null;
  customSpecialization: string | null;
  phone: string;
  fiscalCode: string | null;
  lastOrder: any | null;
}

export interface GetOrders_getOrders_orders_agent {
  __typename: "User";
  id: string;
  name: string;
  lastname: string;
  email: string;
  agentCrm: string | null;
}

export interface GetOrders_getOrders_orders_operator {
  __typename: "User";
  id: string;
  name: string;
  lastname: string;
  email: string;
}

export interface GetOrders_getOrders_orders_address {
  __typename: "Address";
  id: string;
  destinationCode: string | null;
  label: string | null;
  addressLine: string;
  zip: string;
  city: string;
  hamlet: string | null;
  province: string;
  region: string;
  careOf: string | null;
  notes: string | null;
}

export interface GetOrders_getOrders_orders_selectedTherapies {
  __typename: "SelectedTherapy";
  therapyId: string;
  productIds: string[];
}

export interface GetOrders_getOrders_orders_selectedProducts {
  __typename: "SelectedProduct";
  productId: string;
  quantity: number;
}

export interface GetOrders_getOrders_orders {
  __typename: "Order";
  id: string;
  status: OrderStatus;
  date: any;
  client: GetOrders_getOrders_orders_client;
  deliveryNotes: string | null;
  agent: GetOrders_getOrders_orders_agent;
  operator: GetOrders_getOrders_orders_operator | null;
  address: GetOrders_getOrders_orders_address;
  selectedTherapies: GetOrders_getOrders_orders_selectedTherapies[];
  selectedProducts: GetOrders_getOrders_orders_selectedProducts[];
}

export interface GetOrders_getOrders {
  __typename: "GetOrdersResults";
  total: number;
  orders: GetOrders_getOrders_orders[];
}

export interface GetOrders {
  getOrders: GetOrders_getOrders;
}

export interface GetOrdersVariables {
  offset?: number | null;
  limit?: number | null;
  where?: OrderWhereInput | null;
  order?: OrderOrderInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetOrder
// ====================================================

export interface GetOrder_getOrder_client {
  __typename: "Client";
  id: string;
  name: string;
  lastname: string;
  active: boolean;
  email: string;
  verified: boolean;
  profession: Profession;
  specialization: Specialization;
  customProfession: string | null;
  customSpecialization: string | null;
  phone: string;
  fiscalCode: string | null;
  lastOrder: any | null;
}

export interface GetOrder_getOrder_agent {
  __typename: "User";
  id: string;
  name: string;
  lastname: string;
  email: string;
  agentCrm: string | null;
}

export interface GetOrder_getOrder_operator {
  __typename: "User";
  id: string;
  name: string;
  lastname: string;
  email: string;
}

export interface GetOrder_getOrder_address {
  __typename: "Address";
  id: string;
  destinationCode: string | null;
  label: string | null;
  addressLine: string;
  zip: string;
  city: string;
  hamlet: string | null;
  province: string;
  region: string;
  careOf: string | null;
  notes: string | null;
}

export interface GetOrder_getOrder_selectedTherapies {
  __typename: "SelectedTherapy";
  therapyId: string;
  productIds: string[];
}

export interface GetOrder_getOrder_selectedProducts {
  __typename: "SelectedProduct";
  productId: string;
  quantity: number;
}

export interface GetOrder_getOrder {
  __typename: "Order";
  id: string;
  status: OrderStatus;
  date: any;
  client: GetOrder_getOrder_client;
  deliveryNotes: string | null;
  agent: GetOrder_getOrder_agent;
  operator: GetOrder_getOrder_operator | null;
  address: GetOrder_getOrder_address;
  selectedTherapies: GetOrder_getOrder_selectedTherapies[];
  selectedProducts: GetOrder_getOrder_selectedProducts[];
}

export interface GetOrder {
  getOrder: GetOrder_getOrder | null;
}

export interface GetOrderVariables {
  orderId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetOrdersClosed
// ====================================================

export interface GetOrdersClosed_getOrdersClosed_orders_client {
  __typename: "OrderClosedClient";
  id: string;
  name: string;
  lastname: string;
  email: string;
  phone: string;
  fiscalCode: string | null;
  profession: string;
  specialization: string;
  addressLine: string;
  zip: string;
  city: string;
  hamlet: string | null;
  province: string;
  region: string;
  careOf: string | null;
  destinationCode: string;
}

export interface GetOrdersClosed_getOrdersClosed_orders_agent {
  __typename: "OrderClosedAgent";
  id: string;
  crmCode: string;
  name: string;
  lastname: string;
  email: string;
}

export interface GetOrdersClosed_getOrdersClosed_orders_productUnities {
  __typename: "OrderClosedProductUnities";
  id: string;
  name: string;
  boxUnities: number;
  totalUnities: number;
  sku: string;
}

export interface GetOrdersClosed_getOrdersClosed_orders_selectedProducts {
  __typename: "OrderClosedProducts";
  id: string;
  quantity: number;
  name: string;
  sku: string;
}

export interface GetOrdersClosed_getOrdersClosed_orders_selectedTherapies {
  __typename: "OrderClosedTherapies";
  id: string;
  name: string;
  quantity: number;
}

export interface GetOrdersClosed_getOrdersClosed_orders {
  __typename: "OrderClosed";
  id: string;
  finalStatus: OrderFinalStatus;
  orderDate: any;
  client: GetOrdersClosed_getOrdersClosed_orders_client;
  agent: GetOrdersClosed_getOrdersClosed_orders_agent;
  orderNotes: string | null;
  deliveryNotes: string | null;
  productUnities: (GetOrdersClosed_getOrdersClosed_orders_productUnities | null)[];
  selectedProducts: (GetOrdersClosed_getOrdersClosed_orders_selectedProducts | null)[];
  selectedTherapies: (GetOrdersClosed_getOrdersClosed_orders_selectedTherapies | null)[];
}

export interface GetOrdersClosed_getOrdersClosed {
  __typename: "GetOrdersClosedResults";
  total: number;
  orders: GetOrdersClosed_getOrdersClosed_orders[];
}

export interface GetOrdersClosed {
  getOrdersClosed: GetOrdersClosed_getOrdersClosed;
}

export interface GetOrdersClosedVariables {
  offset?: number | null;
  limit?: number | null;
  where?: OrderClosedWhereInput | null;
  order?: OrderClosedOrderInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetOrderClosed
// ====================================================

export interface GetOrderClosed_getOrderClosed_client {
  __typename: "OrderClosedClient";
  id: string;
  name: string;
  lastname: string;
  email: string;
  phone: string;
  fiscalCode: string | null;
  profession: string;
  specialization: string;
  addressLine: string;
  zip: string;
  city: string;
  hamlet: string | null;
  province: string;
  region: string;
  careOf: string | null;
  destinationCode: string;
}

export interface GetOrderClosed_getOrderClosed_agent {
  __typename: "OrderClosedAgent";
  id: string;
  crmCode: string;
  name: string;
  lastname: string;
  email: string;
}

export interface GetOrderClosed_getOrderClosed_productUnities {
  __typename: "OrderClosedProductUnities";
  id: string;
  name: string;
  boxUnities: number;
  totalUnities: number;
  sku: string;
}

export interface GetOrderClosed_getOrderClosed_selectedProducts {
  __typename: "OrderClosedProducts";
  id: string;
  quantity: number;
  name: string;
  sku: string;
}

export interface GetOrderClosed_getOrderClosed_selectedTherapies {
  __typename: "OrderClosedTherapies";
  id: string;
  name: string;
  quantity: number;
}

export interface GetOrderClosed_getOrderClosed {
  __typename: "OrderClosed";
  id: string;
  finalStatus: OrderFinalStatus;
  orderDate: any;
  client: GetOrderClosed_getOrderClosed_client;
  agent: GetOrderClosed_getOrderClosed_agent;
  orderNotes: string | null;
  deliveryNotes: string | null;
  productUnities: (GetOrderClosed_getOrderClosed_productUnities | null)[];
  selectedProducts: (GetOrderClosed_getOrderClosed_selectedProducts | null)[];
  selectedTherapies: (GetOrderClosed_getOrderClosed_selectedTherapies | null)[];
}

export interface GetOrderClosed {
  getOrderClosed: GetOrderClosed_getOrderClosed | null;
}

export interface GetOrderClosedVariables {
  orderId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetDocument
// ====================================================

export interface GetDocument_getMedicalDocument {
  __typename: "MedicalDocument";
  id: string;
  name: string;
  unity: number;
  crmCode: string;
  cover: string | null;
  available: boolean;
}

export interface GetDocument {
  getMedicalDocument: GetDocument_getMedicalDocument | null;
}

export interface GetDocumentVariables {
  medicalDocumentId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetDocuments
// ====================================================

export interface GetDocuments_getMedicalDocuments_medicalDocuments {
  __typename: "MedicalDocument";
  id: string;
  name: string;
  unity: number;
  crmCode: string;
  cover: string | null;
  available: boolean;
}

export interface GetDocuments_getMedicalDocuments {
  __typename: "GetMedicalDocumentsResults";
  total: number;
  medicalDocuments: GetDocuments_getMedicalDocuments_medicalDocuments[];
}

export interface GetDocuments {
  getMedicalDocuments: GetDocuments_getMedicalDocuments;
}

export interface GetDocumentsVariables {
  offset?: number | null;
  limit?: number | null;
  where?: MedicalDocumentWhereInput | null;
  order?: MedicalDocumentOrderInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getSignedUrl
// ====================================================

export interface getSignedUrl_getSignedUrl {
  __typename: "GetSignedUrlResults";
  uploadUrl: string;
  finalUrl: string;
}

export interface getSignedUrl {
  getSignedUrl: getSignedUrl_getSignedUrl;
}

export interface getSignedUrlVariables {
  filename: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMaintenanceMode
// ====================================================

export interface GetMaintenanceMode {
  getMaintenanceMode: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSettings
// ====================================================

export interface GetSettings_getSettings {
  __typename: "Settings";
  id: string;
  value: string;
}

export interface GetSettings {
  getSettings: GetSettings_getSettings | null;
}

export interface GetSettingsVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSalesClients
// ====================================================

export interface GetSalesClients_getSalesClients_salesClients_salesAddresses {
  __typename: "SalesAddress";
  id: string;
  destinationCode: string | null;
  name: string;
  addressLine: string;
  zip: string;
  city: string;
  hamlet: string | null;
  province: string;
  phone: string | null;
  notes: string | null;
}

export interface GetSalesClients_getSalesClients_salesClients_agent {
  __typename: "User";
  id: string;
  name: string;
  lastname: string;
}

export interface GetSalesClients_getSalesClients_salesClients {
  __typename: "SalesClient";
  id: string;
  name: string;
  addressLine: string;
  zip: string;
  city: string;
  hamlet: string | null;
  province: string;
  sdi: string | null;
  pec: string | null;
  iban: string | null;
  active: boolean;
  email: string;
  verified: boolean;
  phone: string;
  vat: string;
  notes: string | null;
  salesAddresses: GetSalesClients_getSalesClients_salesClients_salesAddresses[];
  agent: GetSalesClients_getSalesClients_salesClients_agent | null;
  lastOrder: any | null;
}

export interface GetSalesClients_getSalesClients {
  __typename: "GetSalesClientsResults";
  total: number;
  salesClients: GetSalesClients_getSalesClients_salesClients[];
}

export interface GetSalesClients {
  getSalesClients: GetSalesClients_getSalesClients;
}

export interface GetSalesClientsVariables {
  offset?: number | null;
  limit?: number | null;
  where?: SalesClientWhereInput | null;
  order?: SalesClientOrderInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSalesClientsSimple
// ====================================================

export interface GetSalesClientsSimple_getSalesClients_salesClients {
  __typename: "SalesClient";
  id: string;
  name: string;
}

export interface GetSalesClientsSimple_getSalesClients {
  __typename: "GetSalesClientsResults";
  total: number;
  salesClients: GetSalesClientsSimple_getSalesClients_salesClients[];
}

export interface GetSalesClientsSimple {
  getSalesClients: GetSalesClientsSimple_getSalesClients;
}

export interface GetSalesClientsSimpleVariables {
  offset?: number | null;
  limit?: number | null;
  where?: SalesClientWhereInput | null;
  order?: SalesClientOrderInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSalesClient
// ====================================================

export interface GetSalesClient_getSalesClient_salesAddresses {
  __typename: "SalesAddress";
  id: string;
  destinationCode: string | null;
  name: string;
  addressLine: string;
  zip: string;
  city: string;
  hamlet: string | null;
  province: string;
  phone: string | null;
  notes: string | null;
}

export interface GetSalesClient_getSalesClient_agent {
  __typename: "User";
  id: string;
  name: string;
  lastname: string;
}

export interface GetSalesClient_getSalesClient {
  __typename: "SalesClient";
  id: string;
  name: string;
  addressLine: string;
  zip: string;
  city: string;
  hamlet: string | null;
  province: string;
  sdi: string | null;
  pec: string | null;
  iban: string | null;
  active: boolean;
  email: string;
  verified: boolean;
  phone: string;
  vat: string;
  notes: string | null;
  salesAddresses: GetSalesClient_getSalesClient_salesAddresses[];
  agent: GetSalesClient_getSalesClient_agent | null;
  lastOrder: any | null;
}

export interface GetSalesClient {
  getSalesClient: GetSalesClient_getSalesClient | null;
}

export interface GetSalesClientVariables {
  salesClientId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSalesOrders
// ====================================================

export interface GetSalesOrders_getSalesOrders_salesOrders_salesClient {
  __typename: "SalesClient";
  id: string;
  name: string;
  addressLine: string;
  zip: string;
  city: string;
  hamlet: string | null;
  province: string;
  notes: string | null;
  iban: string | null;
  pec: string | null;
  sdi: string | null;
  active: boolean;
  email: string;
  verified: boolean;
  phone: string;
  vat: string;
  lastOrder: any | null;
}

export interface GetSalesOrders_getSalesOrders_salesOrders_agent {
  __typename: "User";
  id: string;
  name: string;
  lastname: string;
  email: string;
  agentCrm: string | null;
}

export interface GetSalesOrders_getSalesOrders_salesOrders_operator {
  __typename: "User";
  id: string;
  name: string;
  lastname: string;
  email: string;
}

export interface GetSalesOrders_getSalesOrders_salesOrders_salesAddress {
  __typename: "SalesAddress";
  id: string;
  destinationCode: string | null;
  name: string;
  addressLine: string;
  zip: string;
  city: string;
  hamlet: string | null;
  province: string;
  notes: string | null;
  phone: string | null;
}

export interface GetSalesOrders_getSalesOrders_salesOrders_selectedSalesProducts {
  __typename: "SelectedSalesProduct";
  productId: string;
  quantitySale: number;
  quantityDiscount: number;
  quantitySample: number;
}

export interface GetSalesOrders_getSalesOrders_salesOrders {
  __typename: "SalesOrder";
  id: string;
  status: SalesOrderStatus;
  payment: SalesOrderPayment;
  date: any;
  discount: number;
  salesClient: GetSalesOrders_getSalesOrders_salesOrders_salesClient;
  deliveryNotes: string | null;
  agent: GetSalesOrders_getSalesOrders_salesOrders_agent;
  operator: GetSalesOrders_getSalesOrders_salesOrders_operator | null;
  salesAddress: GetSalesOrders_getSalesOrders_salesOrders_salesAddress;
  selectedSalesProducts: GetSalesOrders_getSalesOrders_salesOrders_selectedSalesProducts[];
}

export interface GetSalesOrders_getSalesOrders {
  __typename: "GetSalesOrdersResults";
  total: number;
  salesOrders: GetSalesOrders_getSalesOrders_salesOrders[];
}

export interface GetSalesOrders {
  getSalesOrders: GetSalesOrders_getSalesOrders;
}

export interface GetSalesOrdersVariables {
  offset?: number | null;
  limit?: number | null;
  where?: SalesOrderWhereInput | null;
  order?: SalesOrderOrderInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSalesOrder
// ====================================================

export interface GetSalesOrder_getSalesOrder_salesClient {
  __typename: "SalesClient";
  id: string;
  name: string;
  addressLine: string;
  zip: string;
  city: string;
  hamlet: string | null;
  province: string;
  notes: string | null;
  iban: string | null;
  pec: string | null;
  sdi: string | null;
  active: boolean;
  email: string;
  verified: boolean;
  phone: string;
  vat: string;
  lastOrder: any | null;
}

export interface GetSalesOrder_getSalesOrder_agent {
  __typename: "User";
  id: string;
  name: string;
  lastname: string;
  email: string;
  agentCrm: string | null;
}

export interface GetSalesOrder_getSalesOrder_operator {
  __typename: "User";
  id: string;
  name: string;
  lastname: string;
  email: string;
}

export interface GetSalesOrder_getSalesOrder_salesAddress {
  __typename: "SalesAddress";
  id: string;
  destinationCode: string | null;
  name: string;
  addressLine: string;
  zip: string;
  city: string;
  hamlet: string | null;
  province: string;
  notes: string | null;
  phone: string | null;
}

export interface GetSalesOrder_getSalesOrder_selectedSalesProducts {
  __typename: "SelectedSalesProduct";
  productId: string;
  quantitySale: number;
  quantityDiscount: number;
  quantitySample: number;
}

export interface GetSalesOrder_getSalesOrder {
  __typename: "SalesOrder";
  id: string;
  status: SalesOrderStatus;
  payment: SalesOrderPayment;
  date: any;
  discount: number;
  salesClient: GetSalesOrder_getSalesOrder_salesClient;
  deliveryNotes: string | null;
  agent: GetSalesOrder_getSalesOrder_agent;
  operator: GetSalesOrder_getSalesOrder_operator | null;
  salesAddress: GetSalesOrder_getSalesOrder_salesAddress;
  selectedSalesProducts: GetSalesOrder_getSalesOrder_selectedSalesProducts[];
}

export interface GetSalesOrder {
  getSalesOrder: GetSalesOrder_getSalesOrder | null;
}

export interface GetSalesOrderVariables {
  salesOrderId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSalesOrdersClosed
// ====================================================

export interface GetSalesOrdersClosed_getSalesOrdersClosed_salesOrders_salesClient {
  __typename: "SalesOrderClosedClient";
  id: string;
  name: string;
  email: string;
  destinationCode: string;
  phone: string;
  vat: string | null;
  iban: string | null;
  sdi: string | null;
  pec: string | null;
  addressLine: string;
  zip: string;
  city: string;
  hamlet: string | null;
  province: string;
  shippingName: string;
  shippingPhone: string | null;
  shippingAddressLine: string;
  shippingZip: string;
  shippingCity: string;
  shippingHamlet: string | null;
  shippingProvince: string;
}

export interface GetSalesOrdersClosed_getSalesOrdersClosed_salesOrders_agent {
  __typename: "SalesOrderClosedAgent";
  id: string;
  crmCode: string;
  name: string;
  lastname: string;
  email: string;
}

export interface GetSalesOrdersClosed_getSalesOrdersClosed_salesOrders_selectedSalesProducts {
  __typename: "SalesOrderClosedProducts";
  id: string;
  quantitySale: number;
  quantityDiscount: number;
  quantitySample: number;
  name: string;
  sku: string;
  ean: string;
  price: number;
}

export interface GetSalesOrdersClosed_getSalesOrdersClosed_salesOrders {
  __typename: "SalesOrderClosed";
  id: string;
  finalStatus: SalesOrderFinalStatus;
  finalPayment: SalesOrderPayment;
  salesOrderDate: any;
  salesOrderNotes: string | null;
  deliveryNotes: string | null;
  discount: number;
  salesClient: GetSalesOrdersClosed_getSalesOrdersClosed_salesOrders_salesClient;
  agent: GetSalesOrdersClosed_getSalesOrdersClosed_salesOrders_agent;
  selectedSalesProducts: GetSalesOrdersClosed_getSalesOrdersClosed_salesOrders_selectedSalesProducts[];
}

export interface GetSalesOrdersClosed_getSalesOrdersClosed {
  __typename: "GetSalesOrdersClosedResults";
  total: number;
  salesOrders: GetSalesOrdersClosed_getSalesOrdersClosed_salesOrders[];
}

export interface GetSalesOrdersClosed {
  getSalesOrdersClosed: GetSalesOrdersClosed_getSalesOrdersClosed;
}

export interface GetSalesOrdersClosedVariables {
  offset?: number | null;
  limit?: number | null;
  where?: SalesOrderClosedWhereInput | null;
  order?: SalesOrderClosedOrderInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSalesOrderClosed
// ====================================================

export interface GetSalesOrderClosed_getSalesOrderClosed_salesClient {
  __typename: "SalesOrderClosedClient";
  id: string;
  name: string;
  email: string;
  destinationCode: string;
  phone: string;
  vat: string | null;
  iban: string | null;
  sdi: string | null;
  pec: string | null;
  addressLine: string;
  zip: string;
  city: string;
  hamlet: string | null;
  province: string;
  shippingName: string;
  shippingPhone: string | null;
  shippingAddressLine: string;
  shippingZip: string;
  shippingCity: string;
  shippingHamlet: string | null;
  shippingProvince: string;
}

export interface GetSalesOrderClosed_getSalesOrderClosed_agent {
  __typename: "SalesOrderClosedAgent";
  id: string;
  crmCode: string;
  name: string;
  lastname: string;
  email: string;
}

export interface GetSalesOrderClosed_getSalesOrderClosed_selectedSalesProducts {
  __typename: "SalesOrderClosedProducts";
  id: string;
  quantitySale: number;
  quantityDiscount: number;
  quantitySample: number;
  name: string;
  sku: string;
  ean: string;
  price: number;
}

export interface GetSalesOrderClosed_getSalesOrderClosed {
  __typename: "SalesOrderClosed";
  id: string;
  finalStatus: SalesOrderFinalStatus;
  finalPayment: SalesOrderPayment;
  salesOrderDate: any;
  salesOrderNotes: string | null;
  deliveryNotes: string | null;
  discount: number;
  salesClient: GetSalesOrderClosed_getSalesOrderClosed_salesClient;
  agent: GetSalesOrderClosed_getSalesOrderClosed_agent;
  selectedSalesProducts: GetSalesOrderClosed_getSalesOrderClosed_selectedSalesProducts[];
}

export interface GetSalesOrderClosed {
  getSalesOrderClosed: GetSalesOrderClosed_getSalesOrderClosed | null;
}

export interface GetSalesOrderClosedVariables {
  salesOrderId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL subscription operation: MaintenanceMode
// ====================================================

export interface MaintenanceMode {
  maintenanceMode: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProductFields
// ====================================================

export interface ProductFields {
  __typename: "Product";
  id: string;
  name: string;
  description: string | null;
  sku: string;
  unity: number;
  ean: string | null;
  forSale: boolean;
  price: number;
  available: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TherapyFields
// ====================================================

export interface TherapyFields_therapyEntries_products {
  __typename: "Product";
  id: string;
  name: string;
  description: string | null;
  sku: string;
  unity: number;
  ean: string | null;
  forSale: boolean;
  price: number;
  available: boolean;
}

export interface TherapyFields_therapyEntries {
  __typename: "TherapyEntry";
  quantity: number;
  products: TherapyFields_therapyEntries_products[];
}

export interface TherapyFields {
  __typename: "Therapy";
  id: string;
  name: string;
  therapyEntries: TherapyFields_therapyEntries[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UserFields
// ====================================================

export interface UserFields {
  __typename: "User";
  id: string;
  name: string;
  lastname: string;
  active: boolean;
  email: string;
  role: UserRole;
  agentCrm: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ClientFields
// ====================================================

export interface ClientFields_addresses {
  __typename: "Address";
  id: string;
  destinationCode: string | null;
  label: string | null;
  addressLine: string;
  zip: string;
  city: string;
  hamlet: string | null;
  province: string;
  region: string;
  careOf: string | null;
  notes: string | null;
}

export interface ClientFields_agent {
  __typename: "User";
  id: string;
  name: string;
  lastname: string;
}

export interface ClientFields {
  __typename: "Client";
  id: string;
  name: string;
  lastname: string;
  active: boolean;
  email: string;
  verified: boolean;
  addresses: ClientFields_addresses[];
  profession: Profession;
  specialization: Specialization;
  customProfession: string | null;
  customSpecialization: string | null;
  notes: string | null;
  phone: string;
  fiscalCode: string | null;
  agent: ClientFields_agent | null;
  lastOrder: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: OrderFields
// ====================================================

export interface OrderFields_client {
  __typename: "Client";
  id: string;
  name: string;
  lastname: string;
  active: boolean;
  email: string;
  verified: boolean;
  profession: Profession;
  specialization: Specialization;
  customProfession: string | null;
  customSpecialization: string | null;
  phone: string;
  fiscalCode: string | null;
  lastOrder: any | null;
}

export interface OrderFields_agent {
  __typename: "User";
  id: string;
  name: string;
  lastname: string;
  email: string;
  agentCrm: string | null;
}

export interface OrderFields_operator {
  __typename: "User";
  id: string;
  name: string;
  lastname: string;
  email: string;
}

export interface OrderFields_address {
  __typename: "Address";
  id: string;
  destinationCode: string | null;
  label: string | null;
  addressLine: string;
  zip: string;
  city: string;
  hamlet: string | null;
  province: string;
  region: string;
  careOf: string | null;
  notes: string | null;
}

export interface OrderFields_selectedTherapies {
  __typename: "SelectedTherapy";
  therapyId: string;
  productIds: string[];
}

export interface OrderFields_selectedProducts {
  __typename: "SelectedProduct";
  productId: string;
  quantity: number;
}

export interface OrderFields {
  __typename: "Order";
  id: string;
  status: OrderStatus;
  date: any;
  client: OrderFields_client;
  deliveryNotes: string | null;
  agent: OrderFields_agent;
  operator: OrderFields_operator | null;
  address: OrderFields_address;
  selectedTherapies: OrderFields_selectedTherapies[];
  selectedProducts: OrderFields_selectedProducts[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: OrderClosedFields
// ====================================================

export interface OrderClosedFields_client {
  __typename: "OrderClosedClient";
  id: string;
  name: string;
  lastname: string;
  email: string;
  phone: string;
  fiscalCode: string | null;
  profession: string;
  specialization: string;
  addressLine: string;
  zip: string;
  city: string;
  hamlet: string | null;
  province: string;
  region: string;
  careOf: string | null;
  destinationCode: string;
}

export interface OrderClosedFields_agent {
  __typename: "OrderClosedAgent";
  id: string;
  crmCode: string;
  name: string;
  lastname: string;
  email: string;
}

export interface OrderClosedFields_productUnities {
  __typename: "OrderClosedProductUnities";
  id: string;
  name: string;
  boxUnities: number;
  totalUnities: number;
  sku: string;
}

export interface OrderClosedFields_selectedProducts {
  __typename: "OrderClosedProducts";
  id: string;
  quantity: number;
  name: string;
  sku: string;
}

export interface OrderClosedFields_selectedTherapies {
  __typename: "OrderClosedTherapies";
  id: string;
  name: string;
  quantity: number;
}

export interface OrderClosedFields {
  __typename: "OrderClosed";
  id: string;
  finalStatus: OrderFinalStatus;
  orderDate: any;
  client: OrderClosedFields_client;
  agent: OrderClosedFields_agent;
  orderNotes: string | null;
  deliveryNotes: string | null;
  productUnities: (OrderClosedFields_productUnities | null)[];
  selectedProducts: (OrderClosedFields_selectedProducts | null)[];
  selectedTherapies: (OrderClosedFields_selectedTherapies | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: DocumentFields
// ====================================================

export interface DocumentFields {
  __typename: "MedicalDocument";
  id: string;
  name: string;
  unity: number;
  crmCode: string;
  cover: string | null;
  available: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SalesClientFields
// ====================================================

export interface SalesClientFields_salesAddresses {
  __typename: "SalesAddress";
  id: string;
  destinationCode: string | null;
  name: string;
  addressLine: string;
  zip: string;
  city: string;
  hamlet: string | null;
  province: string;
  phone: string | null;
  notes: string | null;
}

export interface SalesClientFields_agent {
  __typename: "User";
  id: string;
  name: string;
  lastname: string;
}

export interface SalesClientFields {
  __typename: "SalesClient";
  id: string;
  name: string;
  addressLine: string;
  zip: string;
  city: string;
  hamlet: string | null;
  province: string;
  sdi: string | null;
  pec: string | null;
  iban: string | null;
  active: boolean;
  email: string;
  verified: boolean;
  phone: string;
  vat: string;
  notes: string | null;
  salesAddresses: SalesClientFields_salesAddresses[];
  agent: SalesClientFields_agent | null;
  lastOrder: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SalesOrderFields
// ====================================================

export interface SalesOrderFields_salesClient {
  __typename: "SalesClient";
  id: string;
  name: string;
  addressLine: string;
  zip: string;
  city: string;
  hamlet: string | null;
  province: string;
  notes: string | null;
  iban: string | null;
  pec: string | null;
  sdi: string | null;
  active: boolean;
  email: string;
  verified: boolean;
  phone: string;
  vat: string;
  lastOrder: any | null;
}

export interface SalesOrderFields_agent {
  __typename: "User";
  id: string;
  name: string;
  lastname: string;
  email: string;
  agentCrm: string | null;
}

export interface SalesOrderFields_operator {
  __typename: "User";
  id: string;
  name: string;
  lastname: string;
  email: string;
}

export interface SalesOrderFields_salesAddress {
  __typename: "SalesAddress";
  id: string;
  destinationCode: string | null;
  name: string;
  addressLine: string;
  zip: string;
  city: string;
  hamlet: string | null;
  province: string;
  notes: string | null;
  phone: string | null;
}

export interface SalesOrderFields_selectedSalesProducts {
  __typename: "SelectedSalesProduct";
  productId: string;
  quantitySale: number;
  quantityDiscount: number;
  quantitySample: number;
}

export interface SalesOrderFields {
  __typename: "SalesOrder";
  id: string;
  status: SalesOrderStatus;
  payment: SalesOrderPayment;
  date: any;
  discount: number;
  salesClient: SalesOrderFields_salesClient;
  deliveryNotes: string | null;
  agent: SalesOrderFields_agent;
  operator: SalesOrderFields_operator | null;
  salesAddress: SalesOrderFields_salesAddress;
  selectedSalesProducts: SalesOrderFields_selectedSalesProducts[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SalesOrderClosedFields
// ====================================================

export interface SalesOrderClosedFields_salesClient {
  __typename: "SalesOrderClosedClient";
  id: string;
  name: string;
  email: string;
  destinationCode: string;
  phone: string;
  vat: string | null;
  iban: string | null;
  sdi: string | null;
  pec: string | null;
  addressLine: string;
  zip: string;
  city: string;
  hamlet: string | null;
  province: string;
  shippingName: string;
  shippingPhone: string | null;
  shippingAddressLine: string;
  shippingZip: string;
  shippingCity: string;
  shippingHamlet: string | null;
  shippingProvince: string;
}

export interface SalesOrderClosedFields_agent {
  __typename: "SalesOrderClosedAgent";
  id: string;
  crmCode: string;
  name: string;
  lastname: string;
  email: string;
}

export interface SalesOrderClosedFields_selectedSalesProducts {
  __typename: "SalesOrderClosedProducts";
  id: string;
  quantitySale: number;
  quantityDiscount: number;
  quantitySample: number;
  name: string;
  sku: string;
  ean: string;
  price: number;
}

export interface SalesOrderClosedFields {
  __typename: "SalesOrderClosed";
  id: string;
  finalStatus: SalesOrderFinalStatus;
  finalPayment: SalesOrderPayment;
  salesOrderDate: any;
  salesOrderNotes: string | null;
  deliveryNotes: string | null;
  discount: number;
  salesClient: SalesOrderClosedFields_salesClient;
  agent: SalesOrderClosedFields_agent;
  selectedSalesProducts: SalesOrderClosedFields_selectedSalesProducts[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ClientOrderInput {
  CREATION_ASC = "CREATION_ASC",
  CREATION_DESC = "CREATION_DESC",
  LASTNAME_ASC = "LASTNAME_ASC",
  LASTNAME_DESC = "LASTNAME_DESC",
}

export enum MedicalDocumentOrderInput {
  CREATION_ASC = "CREATION_ASC",
  CREATION_DESC = "CREATION_DESC",
  NAME_ASC = "NAME_ASC",
  NAME_DESC = "NAME_DESC",
}

export enum OrderClosedOrderInput {
  CREATION_ASC = "CREATION_ASC",
  CREATION_DESC = "CREATION_DESC",
}

export enum OrderFinalStatus {
  APPROVED = "APPROVED",
  REFUSED = "REFUSED",
}

export enum OrderOrderInput {
  CREATION_ASC = "CREATION_ASC",
  CREATION_DESC = "CREATION_DESC",
}

export enum OrderStatus {
  CLOSED = "CLOSED",
  PENDING = "PENDING",
}

export enum ProductOrderInput {
  CREATION_ASC = "CREATION_ASC",
  CREATION_DESC = "CREATION_DESC",
  NAME_ASC = "NAME_ASC",
  NAME_DESC = "NAME_DESC",
}

export enum Profession {
  ALTRO = "ALTRO",
  BIOLOGO = "BIOLOGO",
  DIETISTA = "DIETISTA",
  DISCIPLINE_OLISTICHE = "DISCIPLINE_OLISTICHE",
  FARMACISTA = "FARMACISTA",
  FISIOTERAPISTA = "FISIOTERAPISTA",
  IGIENISTA_DENTALE = "IGIENISTA_DENTALE",
  MEDICO_CHIRURGO = "MEDICO_CHIRURGO",
  ODONTOIATRA = "ODONTOIATRA",
  PROFESSIONI_SANITARIE_DELLA_RIABILITAZIONE = "PROFESSIONI_SANITARIE_DELLA_RIABILITAZIONE",
  PROFESSIONI_SANITARIE_INFERMIERISTICHE_OSTETRICHE = "PROFESSIONI_SANITARIE_INFERMIERISTICHE_OSTETRICHE",
  PROFESSIONI_TECNICO_SANITARIE = "PROFESSIONI_TECNICO_SANITARIE",
  PSICOLOGO = "PSICOLOGO",
}

export enum SalesClientOrderInput {
  CREATION_ASC = "CREATION_ASC",
  CREATION_DESC = "CREATION_DESC",
  NAME_ASC = "NAME_ASC",
  NAME_DESC = "NAME_DESC",
}

export enum SalesOrderClosedOrderInput {
  CREATION_ASC = "CREATION_ASC",
  CREATION_DESC = "CREATION_DESC",
}

export enum SalesOrderFinalStatus {
  APPROVED = "APPROVED",
  REFUSED = "REFUSED",
}

export enum SalesOrderOrderInput {
  CREATION_ASC = "CREATION_ASC",
  CREATION_DESC = "CREATION_DESC",
}

export enum SalesOrderPayment {
  AS_IS = "AS_IS",
  DAY_30_DF = "DAY_30_DF",
  TRANSFER = "TRANSFER",
}

export enum SalesOrderStatus {
  CLOSED = "CLOSED",
  PENDING = "PENDING",
}

export enum Specialization {
  ALLERGOLOGIA_IMMUNOLOGIA_CLINICA = "ALLERGOLOGIA_IMMUNOLOGIA_CLINICA",
  ALTRO = "ALTRO",
  ANESTESIA_RIANIMAZIONE = "ANESTESIA_RIANIMAZIONE",
  BIOLOGO_NUTRIZIONISTA = "BIOLOGO_NUTRIZIONISTA",
  CARDIOLOGIA = "CARDIOLOGIA",
  CHIRURGIA_GENERALE = "CHIRURGIA_GENERALE",
  DERMATOLOGIA_VENEREOLOGIA = "DERMATOLOGIA_VENEREOLOGIA",
  DIETISTA = "DIETISTA",
  EMATOLOGIA = "EMATOLOGIA",
  ENDOCRINOLOGIA = "ENDOCRINOLOGIA",
  EPIDEMIOLOGIA = "EPIDEMIOLOGIA",
  ERBORISTA = "ERBORISTA",
  FARMACISTA = "FARMACISTA",
  FISIATRIA = "FISIATRIA",
  FISIOTERAPIA = "FISIOTERAPIA",
  FISIOTERAPIA_DEL_PAVIMENTO_PELVICO = "FISIOTERAPIA_DEL_PAVIMENTO_PELVICO",
  FITOTERAPIA = "FITOTERAPIA",
  GASTROENTEROLOGIA = "GASTROENTEROLOGIA",
  GENETICA_MEDICA = "GENETICA_MEDICA",
  GERIATRIA = "GERIATRIA",
  GINECOLOGIA_OSTETRICIA = "GINECOLOGIA_OSTETRICIA",
  IGIENE_E_MEDICINA_PREVENTIVA = "IGIENE_E_MEDICINA_PREVENTIVA",
  IGIENISTA_DENTALE = "IGIENISTA_DENTALE",
  INFERMIERE = "INFERMIERE",
  INFERMIERE_PEDIATRICO = "INFERMIERE_PEDIATRICO",
  IRIDOLOGIA = "IRIDOLOGIA",
  KINESIOLOGIA = "KINESIOLOGIA",
  LOGOPEDISTA = "LOGOPEDISTA",
  MALATTIE_APPARATO_RESPIRATORIO = "MALATTIE_APPARATO_RESPIRATORIO",
  MALATTIE_INFETTIVE = "MALATTIE_INFETTIVE",
  MASSOKINESITERAPIA = "MASSOKINESITERAPIA",
  MEDICINA_GENERALE = "MEDICINA_GENERALE",
  MEDICINA_INTERNA = "MEDICINA_INTERNA",
  MEDICINA_LAVORO = "MEDICINA_LAVORO",
  MEDICINA_LEGALE = "MEDICINA_LEGALE",
  MEDICINA_SPORT = "MEDICINA_SPORT",
  MICROBIOLOGIA_VIROLOGIA = "MICROBIOLOGIA_VIROLOGIA",
  NATUROPATA = "NATUROPATA",
  NEFROLOGIA = "NEFROLOGIA",
  NEUROCHIRURGIA = "NEUROCHIRURGIA",
  NEUROLOGIA = "NEUROLOGIA",
  NEUROPSICHIATRIA_INFANTILE = "NEUROPSICHIATRIA_INFANTILE",
  ODONTOIATRA = "ODONTOIATRA",
  OFTALMOLOGIA = "OFTALMOLOGIA",
  OMEOPATA = "OMEOPATA",
  ONCOLOGIA = "ONCOLOGIA",
  ORTOPEDIA_TRAUMATOLOGIA = "ORTOPEDIA_TRAUMATOLOGIA",
  ORTOTTISTA_ASSISTENTE_DI_OFTALMOLOGIA = "ORTOTTISTA_ASSISTENTE_DI_OFTALMOLOGIA",
  OSTEOPATIA = "OSTEOPATIA",
  OSTETRICA = "OSTETRICA",
  OTORINOLARINGOIATRIA = "OTORINOLARINGOIATRIA",
  PATOLOGIA_CLINICA = "PATOLOGIA_CLINICA",
  PEDIATRIA = "PEDIATRIA",
  PODOLOGO = "PODOLOGO",
  PRIVO_DI_SPECIALIZZAZIONE = "PRIVO_DI_SPECIALIZZAZIONE",
  PSICHIATRIA = "PSICHIATRIA",
  PSICOLOGIA = "PSICOLOGIA",
  PSICOTERAPIA = "PSICOTERAPIA",
  REUMATOLOGIA = "REUMATOLOGIA",
  RIFLESSOLOGIA = "RIFLESSOLOGIA",
  SCIENZA_ALIMENTAZIONE_DIETETICA = "SCIENZA_ALIMENTAZIONE_DIETETICA",
  TECNICO_ORTOPEDICO = "TECNICO_ORTOPEDICO",
  TECNICO_SANITARIO_DI_LABORATORIO_MEDICO = "TECNICO_SANITARIO_DI_LABORATORIO_MEDICO",
  TECNICO_SANITARIO_DI_RADIOLOGIA_MEDICA = "TECNICO_SANITARIO_DI_RADIOLOGIA_MEDICA",
  UROLOGIA = "UROLOGIA",
}

export enum TherapyOrderInput {
  CREATION_ASC = "CREATION_ASC",
  CREATION_DESC = "CREATION_DESC",
  NAME_ASC = "NAME_ASC",
  NAME_DESC = "NAME_DESC",
}

export enum UserOrderInput {
  CREATION_ASC = "CREATION_ASC",
  CREATION_DESC = "CREATION_DESC",
  LASTNAME_ASC = "LASTNAME_ASC",
  LASTNAME_DESC = "LASTNAME_DESC",
}

export enum UserRole {
  ADMIN = "ADMIN",
  AGENT = "AGENT",
  OPERATOR = "OPERATOR",
}

export interface ClientWhereInput {
  searchPattern?: string | null;
  profession?: string | null;
  specialization?: string | null;
  active?: boolean | null;
  verified?: boolean | null;
  agentId?: string | null;
  notAssigned?: boolean | null;
}

export interface CreateAddressInput {
  destinationCode?: string | null;
  label?: string | null;
  addressLine: string;
  zip: string;
  city: string;
  hamlet?: string | null;
  province: string;
  region: string;
  careOf?: string | null;
  notes?: string | null;
}

export interface CreateClientInput {
  email: string;
  name: string;
  lastname: string;
  phone: string;
  fiscalCode?: string | null;
  agentId?: string | null;
  addresses?: CreateAddressInput[] | null;
  profession: Profession;
  specialization: Specialization;
  customProfession?: string | null;
  customSpecialization?: string | null;
  verified?: boolean | null;
}

export interface CreateMedicalDocumentInput {
  unity: number;
  name: string;
  crmCode: string;
  cover?: string | null;
  available: boolean;
}

export interface CreateOrderInput {
  date: any;
  deliveryNotes?: string | null;
  clientId: string;
  agentId: string;
  addressId: string;
  selectedTherapies: SelectedTherapyInput[];
  selectedProducts: SelectedProductInput[];
}

export interface CreateProductInput {
  name: string;
  description?: string | null;
  sku: string;
  unity: number;
  ean?: string | null;
  price: number;
  forSale: boolean;
  available: boolean;
}

export interface CreateSalesAddressInput {
  destinationCode?: string | null;
  name: string;
  addressLine: string;
  zip: string;
  city: string;
  hamlet?: string | null;
  province: string;
  notes?: string | null;
  phone?: string | null;
}

export interface CreateSalesClientInput {
  email: string;
  name: string;
  addressLine: string;
  zip: string;
  city: string;
  hamlet?: string | null;
  province: string;
  notes?: string | null;
  iban?: string | null;
  pec?: string | null;
  sdi?: string | null;
  phone: string;
  vat: string;
  agentId?: string | null;
  salesAddresses?: CreateSalesAddressInput[] | null;
  verified?: boolean | null;
}

export interface CreateSalesOrderInput {
  date: any;
  deliveryNotes?: string | null;
  salesClientId: string;
  agentId: string;
  salesAddressId: string;
  selectedSalesProducts: SelectedSalesProductInput[];
  payment: SalesOrderPayment;
  discount?: number | null;
}

export interface CreateTherapyInput {
  name: string;
  therapyEntries: TherapyEntryInput[];
}

export interface CreateUserInput {
  email: string;
  password: string;
  name: string;
  lastname: string;
  role: UserRole;
  agentCrm?: string | null;
}

export interface MedicalDocumentWhereInput {
  searchPattern?: string | null;
  available?: boolean | null;
}

export interface OrderClosedWhereInput {
  agentId?: string | null;
  finalStatus?: OrderFinalStatus | null;
  searchPattern?: string | null;
  startDate?: any | null;
  endDate?: any | null;
}

export interface OrderWhereInput {
  agentId?: string | null;
  operatorId?: string | null;
  assigned?: boolean | null;
  status?: OrderStatus | null;
  searchPattern?: string | null;
}

export interface ProductWhereInput {
  searchPattern?: string | null;
  archived?: boolean | null;
  available?: boolean | null;
  forSale?: boolean | null;
}

export interface RequestDocumentsSamplesInput {
  documentId: string;
  quantity: number;
}

export interface RequestProductsSamplesInput {
  productId: string;
  quantity: number;
}

export interface SalesClientWhereInput {
  searchPattern?: string | null;
  active?: boolean | null;
  verified?: boolean | null;
  agentId?: string | null;
  notAssigned?: boolean | null;
}

export interface SalesOrderClosedWhereInput {
  agentId?: string | null;
  finalStatus?: OrderFinalStatus | null;
  searchPattern?: string | null;
  startDate?: any | null;
  endDate?: any | null;
}

export interface SalesOrderWhereInput {
  agentId?: string | null;
  operatorId?: string | null;
  assigned?: boolean | null;
  status?: OrderStatus | null;
  searchPattern?: string | null;
}

export interface SelectedProductInput {
  productId: string;
  quantity: number;
}

export interface SelectedSalesProductInput {
  productId: string;
  quantitySale: number;
  quantityDiscount: number;
  quantitySample: number;
}

export interface SelectedTherapyInput {
  therapyId: string;
  productIds: string[];
}

export interface TherapyEntryInput {
  quantity: number;
  productsIds: string[];
}

export interface TherapyWhereInput {
  searchPattern?: string | null;
}

export interface UpdateAddressInput {
  id?: string | null;
  destinationCode?: string | null;
  label?: string | null;
  addressLine: string;
  zip: string;
  city: string;
  hamlet?: string | null;
  province: string;
  region: string;
  careOf?: string | null;
  notes?: string | null;
}

export interface UpdateClientInput {
  email?: string | null;
  name?: string | null;
  lastname?: string | null;
  phone?: string | null;
  fiscalCode?: string | null;
  agentId?: string | null;
  verified?: boolean | null;
  profession?: Profession | null;
  specialization?: Specialization | null;
  customProfession?: string | null;
  customSpecialization?: string | null;
  lastOrder?: any | null;
  addresses?: UpdateAddressInput[] | null;
}

export interface UpdateMedicalDocumentInput {
  unity?: number | null;
  name?: string | null;
  crmCode?: string | null;
  cover?: string | null;
  available?: boolean | null;
}

export interface UpdateOrderInput {
  operatorId?: string | null;
  clientId?: string | null;
  deliveryNotes?: string | null;
  addressId?: string | null;
  selectedTherapies?: SelectedTherapyInput[] | null;
  selectedProducts?: SelectedProductInput[] | null;
}

export interface UpdateProductInput {
  name?: string | null;
  description?: string | null;
  sku?: string | null;
  unity?: number | null;
  archived?: boolean | null;
  available?: boolean | null;
  ean?: string | null;
  price?: number | null;
  forSale?: boolean | null;
}

export interface UpdateSalesAddressInput {
  id?: string | null;
  destinationCode?: string | null;
  name: string;
  addressLine: string;
  zip: string;
  city: string;
  hamlet?: string | null;
  province: string;
  notes?: string | null;
  phone?: string | null;
}

export interface UpdateSalesClientInput {
  email?: string | null;
  name?: string | null;
  addressLine?: string | null;
  zip?: string | null;
  city?: string | null;
  hamlet?: string | null;
  province?: string | null;
  notes?: string | null;
  iban?: string | null;
  pec?: string | null;
  sdi?: string | null;
  phone?: string | null;
  vat?: string | null;
  agentId?: string | null;
  verified?: boolean | null;
  lastOrder?: any | null;
  salesAddresses?: UpdateSalesAddressInput[] | null;
}

export interface UpdateSalesOrderInput {
  operatorId?: string | null;
  salesClientId?: string | null;
  deliveryNotes?: string | null;
  salesAddressId?: string | null;
  selectedSalesProducts?: SelectedSalesProductInput[] | null;
  payment?: SalesOrderPayment | null;
  discount?: number | null;
}

export interface UpdateTherapyInput {
  name?: string | null;
  therapyEntries?: TherapyEntryInput[] | null;
}

export interface UpdateUserInput {
  email?: string | null;
  name?: string | null;
  lastname?: string | null;
  agentCrm?: string | null;
  active?: boolean | null;
}

export interface UserWhereInput {
  searchPattern?: string | null;
  role?: UserRole | null;
  active?: boolean | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
