import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  GetClient_getClient_addresses as AddressType,
  ClientFields,
  UpdateClient,
  UpdateClientVariables,
  CreateClient,
  CreateClientVariables,
  GetUsers,
  GetUsersVariables,
  UserOrderInput,
  Profession,
  Specialization,
  CreateAddressInput,
  UserRole,
} from "types/generated/schemaTypes";
import {
  FormControl,
  Grid,
  TextField,
  Typography,
  Divider,
  Switch,
  FormControlLabel,
  InputLabel,
  Select,
  FormHelperText,
  MenuItem,
  makeStyles,
  Fab,
  IconButton,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CREATE_CLIENT, UPDATE_CLIENT } from "mutation";
import { GET_USERS } from "query";
import { AddressForm, DialogForm, ActionType } from "components";
import { Autocomplete } from "@material-ui/lab";
import { formatError, isValidFiscalCode, professionsMap } from "utils";
import {
  Add as AddIcon,
  DeleteForever as DeleteIcon,
  Create as UpdateIcon,
} from "@material-ui/icons";
import { formatDate } from "utils/date";

const useStyles = makeStyles((theme) => ({
  verifiedSwitch: {
    "&$checked": {
      color: theme.palette.success.main,
      // color: "yellow",
    },
    "&$checked + $track": {
      backgroundColor: theme.palette.success.main,
    },
  },
  track: {
    backgroundColor: theme.palette.success.main,
  },
  checked: {},
}));

export interface ClientFormValues {
  name: string;
  lastname: string;
  email: string;
  fiscalCode: string;
  phone: string;
  active: boolean;
  agentId: string | null;
  profession: Profession | string;
  specialization: Specialization | string;
  customProfession: string;
  customSpecialization: string;
  verified: boolean;
  addresses: {
    id?: string | null;
    destinationCode: string | null;
    label: string | null;
    addressLine: string;
    zip: string;
    city: string;
    hamlet: string | null;
    province: string;
    region: string;
    careOf: string | null;
    notes: string | null;
  }[];
}

const defaultInitialValues = {
  name: "",
  lastname: "",
  fiscalCode: "",
  email: "",
  phone: "",
  active: true,
  agentId: null,
  profession: "",
  specialization: "",
  customProfession: "",
  customSpecialization: "",
  verified: false,
  addresses: [],
};

const parseInitialValue = (input: ClientFields): ClientFormValues => {
  const values: ClientFormValues = {
    name: input.name,
    lastname: input.lastname,
    email: input.email,
    phone: input.phone,
    fiscalCode: input.fiscalCode || "",
    active: input.active,
    agentId: input.agent?.id || null,
    profession: input.profession,
    specialization: input.specialization,
    customProfession: input.customProfession || "",
    customSpecialization: input.customSpecialization || "",
    verified: input.verified,
    addresses: input.addresses.map((address) => ({
      id: address.id,
      destinationCode: address.destinationCode || "",
      label: address.label || "",
      addressLine: address.addressLine,
      zip: address.zip,
      city: address.city,
      hamlet: address.hamlet || "",
      province: address.province,
      region: address.region,
      careOf: address.careOf || "",
      notes: address.notes || "",
    })),
  };
  return values;
};

const getValidationSchema = (isOperator: boolean) => {
  return Yup.object({
    name: Yup.string().required("Campo obbligatorio"),
    lastname: Yup.string().required("Campo obbligatorio"),
    email: Yup.string()
      .email("Formato mail non valido")
      .required("Campo obbligatorio"),
    addresses: Yup.array(),
    phone: Yup.string().required("Campo obbligatorio"),
    // crmCode: isOperator
    //   ? Yup.string().required("Campo obbligatorio")
    //   : Yup.string(),
    fiscalCode: Yup.string()
      .matches(/^[A-Za-z0-9]+$/, "Sono ammessi solo numeri e caratteri")
      .test({
        name: "fiscalCode",
        exclusive: true,
        message: "Codice fiscale non valido",
        test: (value) => {
          if (!value) return true;
          if (value.length === 0) return true;
          if (value.length !== 16) return false;
          return isValidFiscalCode(value);
        },
      }),

    profession: Yup.mixed().required("Campo obbligatorio"),
    specialization: Yup.mixed().required("Campo obbligatorio"),
    customProfession: Yup.string().when("profession", {
      is: Profession.ALTRO,
      then: Yup.string().required("Campo obbligatorio"),
    }),
    customSpecialization: Yup.string().when("specialization", {
      is: Specialization.ALTRO,
      then: Yup.string().required("Campo obbligatorio"),
    }),
  });
};

export const AddressItem: React.FC<{
  address: CreateAddressInput;
  onUpdate: () => void;
  onDelete: () => void;
  disabled?: boolean;
  isOperator: boolean;
}> = ({ address, onUpdate, onDelete, disabled = false, isOperator }) => {
  const isValid = !!address.destinationCode;
  return (
    <div>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          {address.label && (
            <Typography
              color={isOperator ? (isValid ? "primary" : "error") : "primary"}
              variant="body2"
            >
              {address.label}
            </Typography>
          )}
          <Typography
            color={isOperator ? (isValid ? "primary" : "error") : "primary"}
            variant="body2"
          >
            {address.addressLine}
          </Typography>
          <Typography
            color={isOperator ? (isValid ? "primary" : "error") : "primary"}
            variant="body2"
          >
            {address.zip} {address.hamlet} {address.city} ({address.province} -{" "}
            {address.region})
          </Typography>
          {address.careOf && (
            <Typography
              color={isOperator ? (isValid ? "primary" : "error") : "primary"}
              variant="body2"
            >
              {address.careOf}
            </Typography>
          )}
          {address.notes && (
            <>
              <br />
              <Typography
                color={isOperator ? (isValid ? "primary" : "error") : "primary"}
                variant="body2"
                style={{ fontStyle: "italic" }}
              >
                {address.notes}
              </Typography>
            </>
          )}
        </div>
        <div>
          <IconButton onClick={onUpdate} disabled={disabled}>
            <UpdateIcon color={disabled ? "disabled" : "primary"} />
          </IconButton>
          <IconButton onClick={onDelete} disabled={disabled}>
            <DeleteIcon color={disabled ? "disabled" : "secondary"} />
          </IconButton>
        </div>
      </div>
      <Divider style={{ marginTop: 8, marginBottom: 8 }} />
    </div>
  );
};

interface AgentFormProps {
  open: boolean;
  agentId?: string;
  onClose: () => void;
  onCreate: () => void;
  onUpdate: () => void;
  client: ClientFields | null;
}
export const ClientForm: React.FC<AgentFormProps> = ({
  open,
  onClose,
  onCreate,
  onUpdate,
  client,
  agentId,
}) => {
  const classes = useStyles();
  const [createAgentMutation, { error: createAgentError }] = useMutation<
    CreateClient,
    CreateClientVariables
  >(CREATE_CLIENT, {
    onCompleted: async (data) => {
      formik.resetForm();
      onCreate();
    },
    onError: (err) => {
      console.log("Errore!", err);
    },
  });
  const [showAddressForm, setShowAddressForm] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [addresses, setAddresses] = useState<
    Array<{
      id?: string | null;
      destinationCode?: string | null;
      label?: string | null;
      addressLine: string;
      zip: string;
      city: string;
      hamlet?: string | null;
      province: string;
      region: string;
      careOf?: string | null;
      notes?: string | null;
    }>
  >(client ? client.addresses : []);
  const [currentAddressIndex, setCurrentAddressIndex] = useState(-1);

  const [updateClientMutation, { error: updateAgentError }] = useMutation<
    UpdateClient,
    UpdateClientVariables
  >(UPDATE_CLIENT, {
    onCompleted: async (data) => {
      formik.resetForm();
      onUpdate();
    },
    onError: (err) => {
      console.log("Errore!", err);
    },
  });
  const { data: agentsData } = useQuery<GetUsers, GetUsersVariables>(
    GET_USERS,
    {
      fetchPolicy: "network-only",
      variables: {
        where: { active: true, role: UserRole.AGENT },
        order: UserOrderInput.LASTNAME_ASC,
        offset: 0,
        limit: 100,
      },
    }
  );
  const agentsOptions = agentsData
    ? agentsData?.getUsers.users.map((agent) => ({
        label: `${agent.name} ${agent.lastname}`,
        id: agent.id,
      }))
    : [];

  const update = !!client;
  const formik = useFormik<ClientFormValues>({
    initialValues: client
      ? parseInitialValue(client as ClientFields)
      : defaultInitialValues,
    validationSchema: getValidationSchema(!agentId),
    enableReinitialize: true,
    onSubmit: async (values) => {
      let variables: CreateClientVariables | UpdateClientVariables;
      // update
      if (update) {
        variables = {
          clientData: {
            name: formik.values.name,
            lastname: formik.values.lastname,
            fiscalCode: formik.values.fiscalCode || null,
            email: formik.values.email,
            phone: formik.values.phone,
            agentId: agentId || formik.values.agentId,
            profession: formik.values.profession as Profession,
            specialization: formik.values.specialization as Specialization,
            customProfession: formik.values.customProfession || null,
            customSpecialization: formik.values.customSpecialization || null,
            // verified: formik.values.verified,
            addresses: addresses.map((address) => ({
              id: address.id || null,
              destinationCode: address.destinationCode || null,
              label: address.label || null,
              addressLine: address.addressLine,
              zip: address.zip,
              city: address.city,
              hamlet: address.hamlet || null,
              province: address.province,
              region: address.region,
              careOf: address.careOf || null,
              notes: address.notes || null,
            })),
          },
          clientId: (client as ClientFields).id,
        } as UpdateClientVariables;
        if (!agentId) {
          variables.clientData.verified = formik.values.verified;
        }

        await updateClientMutation({ variables });
        // create
      } else {
        variables = {
          clientData: {
            name: formik.values.name,
            lastname: formik.values.lastname,
            fiscalCode: formik.values.fiscalCode || null,
            email: formik.values.email,
            phone: formik.values.phone,
            agentId: agentId || formik.values.agentId,
            profession: formik.values.profession as Profession,
            specialization: formik.values.specialization as Specialization,
            customProfession: formik.values.customProfession || null,
            customSpecialization: formik.values.customSpecialization || null,
            addresses: addresses.map((address) => ({
              destinationCode: address.destinationCode || null,
              label: address.label || null,
              addressLine: address.addressLine,
              zip: address.zip,
              city: address.city,
              hamlet: address.hamlet || null,
              province: address.province,
              region: address.region,
              careOf: address.careOf || null,
              notes: address.notes || null,
            })),
          },
        } as CreateClientVariables;
        if (!agentId) {
          variables.clientData.verified = formik.values.verified;
        }
        await createAgentMutation({ variables });

        // variables = {
        //   userData: {
        //     name: values.name,
        //     lastname: values.lastname,
        //     email: values.email,
        //     crmCode: values.crmCode,
        //     role: "AGENT",
        //     password: Math.random().toString(36).slice(-8),
        //   },
        // } as CreateClientVariables;
      }
    },
  });

  return (
    <DialogForm
      open={open}
      title={update ? "Modifica Cliente" : "Aggiungi un Cliente"}
      onClose={() => {
        formik.resetForm();
        onClose();
      }}
      actions={[
        {
          type: ActionType.EXIT,
          label: "CHIUDI SENZA SALVARE",
          callback: () => {
            formik.resetForm();
            onClose();
          },
          disabled: formik.isSubmitting || showAddressForm,
        },
        {
          type: ActionType.SAVE,
          label: "SALVA",
          callback: formik.submitForm,
          disabled: formik.isSubmitting || showAddressForm || !isChanged,
        },
      ]}
    >
      <div style={{ overflow: "hidden" }}>
        <Typography color="textSecondary" style={{ marginBottom: 24 }}>
          Inserisci tutte le informazioni richieste e salva per aggiungere un
          Cliente.
        </Typography>
        <Typography
          color="textPrimary"
          style={{ marginBottom: 16, marginTop: 8, fontWeight: 700 }}
        >
          Dati personali
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            {/* NAME */}
            <FormControl fullWidth={true}>
              <TextField
                name="name"
                error={formik.touched.name && !!formik.errors.name}
                helperText={
                  formik.touched.name &&
                  !!formik.errors.name &&
                  formik.errors.name
                }
                variant="standard"
                id="name"
                size="small"
                label="Nome*"
                value={formik.values.name}
                onChange={(e) => {
                  formik.setFieldValue("name", e.target.value);
                  setIsChanged(true);
                }}
                disabled={formik.isSubmitting || showAddressForm}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            {/* LASTNAME */}
            <FormControl fullWidth={true}>
              <TextField
                name="lastname"
                error={formik.touched.lastname && !!formik.errors.lastname}
                helperText={
                  formik.touched.lastname &&
                  !!formik.errors.lastname &&
                  formik.errors.lastname
                }
                variant="standard"
                id="lastname"
                size="small"
                label="Cognome*"
                value={formik.values.lastname}
                onChange={(e) => {
                  formik.setFieldValue("lastname", e.target.value);
                  setIsChanged(true);
                }}
                disabled={formik.isSubmitting || showAddressForm}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            {/* EMAIL */}
            <FormControl fullWidth={true}>
              <TextField
                name="email"
                error={formik.touched.email && !!formik.errors.email}
                helperText={
                  formik.touched.email &&
                  !!formik.errors.email &&
                  formik.errors.email
                }
                variant="standard"
                id="email"
                size="small"
                label="Email*"
                value={formik.values.email}
                onChange={(e) => {
                  formik.setFieldValue("email", e.target.value);
                  setIsChanged(true);
                }}
                disabled={formik.isSubmitting || showAddressForm}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            {/* CODICE FISCALE */}
            <FormControl fullWidth={true}>
              <TextField
                name="fiscalCode"
                error={formik.touched.fiscalCode && !!formik.errors.fiscalCode}
                helperText={
                  formik.touched.fiscalCode &&
                  !!formik.errors.fiscalCode &&
                  formik.errors.fiscalCode
                }
                variant="standard"
                id="fiscalCode"
                size="small"
                label="Codice fiscale"
                value={formik.values.fiscalCode}
                onChange={(e) => {
                  formik.setFieldValue("fiscalCode", e.target.value);
                  setIsChanged(true);
                }}
                disabled={formik.isSubmitting || showAddressForm}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            {/* PHONE */}
            <FormControl fullWidth={true}>
              <TextField
                name="phone"
                error={formik.touched.phone && !!formik.errors.phone}
                helperText={
                  formik.touched.phone &&
                  !!formik.errors.phone &&
                  formik.errors.phone
                }
                variant="standard"
                id="phone"
                size="small"
                label="Telefono*"
                value={formik.values.phone}
                onChange={(e) => {
                  formik.setFieldValue("phone", e.target.value);
                  setIsChanged(true);
                }}
                disabled={formik.isSubmitting || showAddressForm}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Typography
          color="textPrimary"
          style={{ marginBottom: 16, fontWeight: 700 }}
        >
          Dettagli
        </Typography>
        <Grid container spacing={1}>
          {!agentId && (
            <>
              <Grid item xs={12} md={6}>
                {/* AGENTE DI RIFERIMENTO */}
                <FormControl fullWidth={true}>
                  <Autocomplete
                    disabled={formik.isSubmitting || showAddressForm}
                    options={agentsOptions}
                    value={
                      agentsOptions.find((agent) => {
                        return agent.id === formik.values.agentId;
                      }) || null
                    }
                    getOptionSelected={(option, test) => {
                      return test.id === option.id;
                    }}
                    onChange={(event, newValue) => {
                      formik.setFieldValue("agentId", newValue?.id || null);
                      setIsChanged(true);
                      //setFilterAgent(newValue?.id || "ALL");
                    }}
                    getOptionLabel={(option) => option.label}
                    // style={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Agente di riferimento"
                        variant="standard"
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </>
          )}

          <Grid item xs={12} md={6}>
            {/* PROFESSIONE */}
            <FormControl fullWidth={true} size="small">
              <InputLabel
                error={formik.touched.profession && !!formik.errors.profession}
                id="profession-label"
              >
                Professione*
              </InputLabel>
              <Select
                name="profession"
                value={formik.values.profession}
                labelId="profession-label"
                id="profession"
                label="Professione*"
                error={formik.touched.profession && !!formik.errors.profession}
                onChange={(e) => {
                  setIsChanged(true);
                  formik.setFieldValue("profession", e.target.value);
                  if (e.target.value === Profession.ALTRO) {
                    formik.setFieldValue(
                      "specialization",
                      Specialization.ALTRO
                    );
                    formik.setFieldValue("customSpecialization", "-");
                  } else {
                    formik.setFieldValue("specialization", "");
                  }
                }}
                disabled={formik.isSubmitting || showAddressForm}
              >
                {[
                  Profession.BIOLOGO,
                  // Profession.DIETISTA,
                  Profession.DISCIPLINE_OLISTICHE,
                  Profession.FARMACISTA,
                  // Profession.FISIOTERAPISTA,
                  // Profession.IGIENISTA_DENTALE,
                  Profession.MEDICO_CHIRURGO,
                  Profession.ODONTOIATRA,
                  Profession.PROFESSIONI_SANITARIE_DELLA_RIABILITAZIONE,
                  Profession.PROFESSIONI_SANITARIE_INFERMIERISTICHE_OSTETRICHE,
                  Profession.PROFESSIONI_TECNICO_SANITARIE,
                  Profession.PSICOLOGO,
                  Profession.ALTRO,
                ].map((item) => (
                  <MenuItem key={item} value={item}>
                    {professionsMap[item].label}
                  </MenuItem>
                ))}
              </Select>
              {formik.touched.profession && !!formik.errors.profession && (
                <FormHelperText error={true} variant="standard">
                  {formik.errors.profession}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          {formik.values.profession !== Profession.ALTRO && (
            <Grid item xs={12} md={6}>
              {/* SPECIALIZZAZIONE */}
              <FormControl fullWidth={true} size="small">
                <InputLabel
                  error={
                    formik.touched.specialization &&
                    !!formik.errors.specialization
                  }
                  id="specialization-label"
                >
                  Specializzazione*
                </InputLabel>
                <Select
                  name="specialization"
                  value={formik.values.specialization}
                  labelId="specialization-label"
                  id="specialization"
                  label="Specializzazione*"
                  error={
                    formik.touched.specialization &&
                    !!formik.errors.specialization
                  }
                  onChange={(e) => {
                    formik.setFieldValue("specialization", e.target.value);
                    setIsChanged(true);
                  }}
                  disabled={formik.isSubmitting || showAddressForm}
                >
                  {formik.values.profession &&
                    professionsMap[
                      formik.values.profession as Profession
                    ].specializations.map(
                      (item: {
                        specialization: Specialization;
                        label: string;
                      }) => {
                        return (
                          <MenuItem
                            key={item.specialization}
                            value={item.specialization}
                          >
                            {item.label}
                          </MenuItem>
                        );
                      }
                    )}
                </Select>
                {formik.touched.specialization &&
                  !!formik.errors.specialization && (
                    <FormHelperText error={true} variant="standard">
                      {formik.errors.specialization}
                    </FormHelperText>
                  )}
              </FormControl>
            </Grid>
          )}
          {formik.values.profession === Profession.ALTRO && (
            <Grid item xs={12} md={6}>
              {/* PROFESSIONE CUSTOM */}
              <FormControl fullWidth={true}>
                <TextField
                  name="customProfession"
                  error={
                    formik.touched.customProfession &&
                    !!formik.errors.customProfession
                  }
                  helperText={
                    formik.touched.customProfession &&
                    !!formik.errors.customProfession &&
                    formik.errors.customProfession
                  }
                  id="customProfession"
                  size="small"
                  label="Indicare la professione*"
                  value={formik.values.customProfession}
                  onChange={(e) => {
                    formik.setFieldValue("customProfession", e.target.value);
                    setIsChanged(true);
                  }}
                  disabled={formik.isSubmitting || showAddressForm}
                />
              </FormControl>
            </Grid>
          )}
          {/* SPECIALIZZAZIONE CUSTOM */}
          {formik.values.specialization === Specialization.ALTRO &&
            formik.values.profession !== Profession.ALTRO && (
              <Grid item xs={12} md={6}>
                <FormControl fullWidth={true}>
                  <TextField
                    name="customSpecialization"
                    error={
                      formik.touched.customSpecialization &&
                      !!formik.errors.customSpecialization
                    }
                    helperText={
                      formik.touched.customSpecialization &&
                      !!formik.errors.customSpecialization &&
                      formik.errors.customSpecialization
                    }
                    id="customSpecialization"
                    size="small"
                    label="Indicare la specializzazione*"
                    value={formik.values.customSpecialization}
                    onChange={(e) => {
                      formik.setFieldValue(
                        "customSpecialization",
                        e.target.value
                      );
                      setIsChanged(true);
                    }}
                    disabled={formik.isSubmitting || showAddressForm}
                  />
                </FormControl>
              </Grid>
            )}
        </Grid>
        <Typography
          color="textPrimary"
          style={{ marginBottom: 16, marginTop: 8, fontWeight: 700 }}
        >
          Indirizzi
        </Typography>

        {addresses.length === 0 && (
          <Typography
            color="textSecondary"
            variant="body1"
            style={{ marginBottom: 16, marginTop: 8 }}
          >
            Non sono ancora presenti indirizzi per questo cliente.
          </Typography>
        )}

        {showAddressForm && (
          <AddressForm
            isAgent={!!agentId}
            open={showAddressForm}
            onClose={() => {
              setShowAddressForm(false);
            }}
            onEdit={()=>{setIsChanged(true)}}
            onCreate={(createdAddress) => {
              setIsChanged(true);
              setAddresses([...addresses, createdAddress]);
              setShowAddressForm(false);
            }}
            onUpdate={(updatedAddress) => {
              setAddresses(
                addresses.map((_, index) => {
                  return index === currentAddressIndex
                    ? updatedAddress
                    : addresses[index];
                })
              );

              formik.setFieldValue(
                "addresses",
                addresses.map((_, index) => {
                  return index === currentAddressIndex
                    ? updatedAddress
                    : addresses[index];
                })
              );
              setShowAddressForm(false);
            }}
            address={
              currentAddressIndex > -1
                ? (addresses[currentAddressIndex] as AddressType)
                : null
            }
          />
        )}

        <Grid container spacing={1}>
          {!showAddressForm && (
            <Grid item xs={12} md={12}>
              {addresses.map((address, index) => {
                return (
                  <AddressItem
                    isOperator={!agentId}
                    key={index}
                    disabled={formik.isSubmitting || showAddressForm}
                    address={address}
                    onUpdate={() => {
                      setCurrentAddressIndex(index);
                      setShowAddressForm(true);
                    }}
                    onDelete={() => {
                      setIsChanged(true);
                      const newAddresses = [...addresses];
                      newAddresses.splice(index, 1);
                      setAddresses(newAddresses);
                      setCurrentAddressIndex(-1);
                    }}
                    // disabled={action.disabled}
                  />
                );
              })}
            </Grid>
          )}
          {!showAddressForm && (
            <Grid item xs={12} md={12}>
              <Fab
                variant="extended"
                color="primary"
                aria-label="add"
                onClick={() => {
                  setCurrentAddressIndex(-1);
                  setShowAddressForm(true);
                }}
                size="small"
              >
                <AddIcon />
                Aggiungi un indirizzo
              </Fab>
            </Grid>
          )}
        </Grid>

        <Typography
          color="textSecondary"
          variant="caption"
          component="p"
          style={{ marginBottom: 30, marginTop: 0 }}
        >
          * I campi contrassegnati con l’asterisco sono obbligatori
        </Typography>
        {client && (
          <Grid container spacing={1}>
            {!agentId && (
              <Grid item xs={12} md={6}>
                <div style={{ paddingLeft: 16 }}>
                  <FormControlLabel
                    control={
                      <Switch
                        // color="primary"
                        classes={{
                          switchBase: classes.verifiedSwitch,
                          checked: classes.checked,
                          track: classes.track,
                        }}
                        checked={formik.values.verified}
                        onChange={() =>{
                          setIsChanged(true);
                          formik.setFieldValue(
                            "verified",
                            !formik.values.verified
                          )
                        }}
                        disableRipple
                        disableTouchRipple
                        disabled={formik.isSubmitting}
                        name="verified"
                      />
                    }
                    label={
                      formik.values.verified
                        ? "Cliente verificato"
                        : "Cliente non verificato"
                    }
                  />
                  {formik.touched.verified && !!formik.errors.verified && (
                    <FormHelperText error={true} variant="standard">
                      {formik.errors.verified}
                    </FormHelperText>
                  )}
                </div>
              </Grid>
            )}

            <Grid item xs={12} md={6}>
              <div style={{ textAlign: agentId ? "left" : "right" }}>
                <Typography
                  variant="body1"
                  color="textPrimary"
                  display="inline"
                >
                  Ultimo ordine:{" "}
                </Typography>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  display="inline"
                >
                  {client.lastOrder ? formatDate(client.lastOrder) : "-"}
                </Typography>
              </div>
            </Grid>
          </Grid>
        )}
        <Divider />

        {(createAgentError || updateAgentError) && (
          <Typography color="error" variant="body1">
            {createAgentError && formatError(createAgentError)}
            {updateAgentError && formatError(updateAgentError)}
          </Typography>
        )}
      </div>
    </DialogForm>
  );
};
